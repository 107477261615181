import { useEffect } from "react";

const useDebounce = (callback: () => void, dependency: string) => {
  useEffect(() => {
    const tmp = setTimeout(() => {
      callback();
    }, 1000);
    return () => {
      clearTimeout(tmp);
    };
  }, [callback, dependency]);
};
export default useDebounce;
