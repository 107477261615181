/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

export function requestHeaderToken(token: string | null) {
  if (token === null) {
    return {};
  }
  const requestHeader: any = useMemo(() => {
    return { Authorization: `Bearer ${token}` };
  }, [token]);
  return requestHeader;
}
