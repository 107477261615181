import React from "react";
import { Typography } from "@mui/material";
import ReportCard from "../../../components/cards/ReportCard";
import { ReportFarmDataType } from "../../../types/DataTypes";

export default function FarmBasicInfo({
  name,
  address,
  county,
  post_code: postCode,
}: ReportFarmDataType) {
  return (
    <ReportCard>
      <Typography variant="h6">{name} Farm</Typography>
      <Typography variant="h6">{address}</Typography>
      <Typography variant="h6">{county}</Typography>
      <Typography variant="h6">{postCode}</Typography>
      <Typography variant="h6">UNITED KINGDOM</Typography>
    </ReportCard>
  );
}
