import { CardContent, CardHeader } from "@mui/material";
import Card from "@mui/material/Card/Card";
import { ReportCardProps } from "../../types/PropsTypes";

function ReportCard({ title, sx, children }: ReportCardProps) {
  return (
    <Card
      sx={{
        borderRadius: 5,
        minHeight: "100%",
        position: "relative",
        ...sx,
      }}
    >
      {title ? <CardHeader align="center" title={title} /> : null}
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default ReportCard;
