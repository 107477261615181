import { toast } from "react-toastify";
import { ErrorTypes } from "../types/DataTypes";

const position = {
  position: toast.POSITION.BOTTOM_RIGHT,
};

export const notify = (message: string, error?: ErrorTypes) => {
  if (error) {
    toast.error(message, position);
  } else {
    toast.success(message, position);
  }
};
