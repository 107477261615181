import * as Yup from "yup";
import { validationSchemaEditUser } from "./validationSchemaEditUser";

export const validationSchemaCreateUser = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email address is required"),
  name: Yup.string()
    .min(3, "Name must contain at least 3 characters")
    .max(25, "Name must contain max 25 characters")
    .required("Name is required"),
  role: Yup.string().required("Role is required"),
  farm: Yup.string().when("role", {
    is: (role: string) => role === "FARM_ADMIN",
    then: Yup.string().required("Please select a farm for this user"),
  }),
  organization: Yup.string().when("role", {
    is: (role: string) => role === "ORGANIZATION_ADMIN",
    then: Yup.string().required("Please select an organization for this user"),
  }),
});
