export type RatingItem = {
  value: number;
  label: string;
};

export const SELECT_RATING_CONSTANTS: RatingItem[] = [
  {
    value: 0,
    label: "N/A",
  },
  {
    value: 1,
    label: "Rating 1",
  },
  {
    value: 2,
    label: "Rating 2",
  },
  {
    value: 3,
    label: "Rating 3",
  },
  {
    value: 4,
    label: "Untrained",
  },
];
