import React from "react";
import { Badge, BadgeProps } from "@mui/material";

function BadgeComponent({ children, badgeContent }: BadgeProps) {
  return (
    <Badge badgeContent={badgeContent} color="primary">
      {children}
    </Badge>
  );
}
export default BadgeComponent;
