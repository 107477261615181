import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FarmDetailsResponseType } from "../../../types/DataTypes";
import ReportCard from "../../../components/cards/ReportCard";

export default function RecordingSiteDetails({
  siteDetails,
  sx,
}: {
  siteDetails: FarmDetailsResponseType;
  sx: any;
}) {
  const {
    red_list: redList,
    species_percent: speciesPercent,
    species_present: speciesPresent,
    unique_at_site: uniqueAtSite,
  } = siteDetails;
  return (
    <ReportCard title="Selected recording site(s)" sx={sx}>
      <List
        sx={{ display: "flex", flexDirection: "column", alignContent: "start" }}
      >
        <ListItem>
          <ListItemIcon>{speciesPresent}</ListItemIcon>
          <ListItemText>Species</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>{redList}</ListItemIcon>{" "}
          <ListItemText>Red list species</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>{uniqueAtSite}</ListItemIcon>
          <ListItemText>
            Species not found elsewhere in the benchmark group of farms
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>{speciesPercent} %</ListItemIcon>
          <ListItemText>
            Total species, as a percentage of all species within benchmark group
            of farms
          </ListItemText>
        </ListItem>
      </List>
    </ReportCard>
  );
}
