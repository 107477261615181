/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { FieldHookConfig, useField } from "formik";

import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

function CheckboxForm({ ...props }: CheckboxProps & FieldHookConfig<string>) {
  const [field, meta] = useField(props);

  return (
    <>
      <Checkbox {...field} {...props} />
      {meta.error ? <FormHelperText error>{meta.error}</FormHelperText> : null}
    </>
  );
}

export default CheckboxForm;
