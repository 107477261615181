/* eslint-disable no-restricted-syntax */
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { REACT_APP_API_URL } from "../constants/apiConstants";
import { UploadFileParams } from "../types/PropsTypes";

dayjs.extend(customParseFormat);
/**
 * Upload recordings for recording site
 * @example
 *  uploadFiles({
        token:'...',
        files: input.files,
        setProgress: console.log,
        recordingSiteId: '...',
        date: new Date(),
    })
  */
export async function uploadFiles({
  token,
  files,
  setProgress,
  setFileUploaded,
  recordingSiteId,
  date = new Date(),
}: UploadFileParams) {
  const NUMBER_OF_FILES = files.length;
  let uploadedCounter = 0;
  const waitingArray: Array<Promise<any>> = [];
  // create initial recording entity
  const recordingRes = await axios.request({
    method: "POST",
    url: `${REACT_APP_API_URL}/recording-sites/${recordingSiteId}/recording`,
    data: JSON.stringify({
      date: date.toISOString(),
      number_of_parts: NUMBER_OF_FILES,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  // Function for uploading one file
  const uploadOneFile = async (file: File) => {
    const res = await axios.request({
      url: `${REACT_APP_API_URL}/recordings/${recordingRes.data.id}/upload-url`,
      method: "PUT",
      data: JSON.stringify({
        name: file.name,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const prom = await axios.request({
      method: "PUT",
      url: res.data,
      data: file,
    });
    uploadedCounter += 1;
    if (setProgress) {
      setProgress(uploadedCounter / NUMBER_OF_FILES);
    }
    if (setFileUploaded) {
      setFileUploaded(file.name);
    }
    return prom;
  };
  // add all files to upload
  for (const file of files) {
    waitingArray.push(uploadOneFile(file));
  }
  // wait for all to finish
  return Promise.allSettled(waitingArray);
}

export type UploadUrlRes = {
  recording_id: string;
  url: string;
  exists: boolean;
};

export type UploadError = {
  sevirity: "ERROR" | "INFO";
  code: string;
  fileName: string;
};
export type UploadFileProps = {
  token: string | null;
  setProgress?: (progress: number) => void;
  setFileUploaded?: (file_name: string) => void;
  recordingSiteId: string;
  setError?: (error: UploadError) => void;
};
export class UploadFile {
  token: string | null;

  setProgress?: (progress: number) => void;

  setFileUploaded?: (file_name: string) => void;

  setError: (error: UploadError) => void;

  recordingSiteId: string;

  uploadedCounter = 0;

  AWS_UPLOAD_FAILS: File[] = [];

  CONFIRM_UPLOAD_FAILS: { recordingId: string; file: File }[] = [];

  NUMBER_OF_FILES = 0;

  constructor({
    token,
    setProgress,
    setFileUploaded,
    recordingSiteId,
    setError,
  }: UploadFileProps) {
    this.token = token;
    this.setProgress = setProgress;
    this.setFileUploaded = setFileUploaded;
    this.recordingSiteId = recordingSiteId;
    this.setError = setError ?? ((err) => undefined);
  }

  async upload(files: File[]) {
    this.NUMBER_OF_FILES = files.length;
    return Promise.all(await this.promisePool(10, files));
  }

  async retry() {
    const tempAWS = [...this.AWS_UPLOAD_FAILS];
    const tempConferm = [...this.CONFIRM_UPLOAD_FAILS];
    this.reset();
    this.NUMBER_OF_FILES = tempConferm.length + tempConferm.length;
    const awsRetrys = await this.promisePool(10, tempAWS);
    const confrmRetrysProm = [];
    for (const confirm of tempConferm) {
      confrmRetrysProm.push(
        this.retryConfirm(confirm.recordingId, confirm.file)
      );
    }
    const confrmRetrys = await Promise.all(confrmRetrysProm);
    return [...awsRetrys, ...confrmRetrys];
  }

  reset() {
    this.uploadedCounter = 0;
    this.AWS_UPLOAD_FAILS = [];
    this.CONFIRM_UPLOAD_FAILS = [];
    this.NUMBER_OF_FILES = 0;
  }

  private async uploadFlow(file: File) {
    const fileName = file.name.toLowerCase();
    // Validate
    const validName = UploadFile.validateFileName(fileName);
    if (!validName) {
      this.setError({
        code: "NOT_VALID_NAME",
        fileName: file.name,
        sevirity: "INFO",
      });
      return false;
    }
    // Get Upload url
    const uploadUrl = await this.getUploadUrl(fileName);
    if (!uploadUrl) {
      this.setError({
        code: "ALREADY_EXISTS",
        fileName: file.name,
        sevirity: "INFO",
      });
      return false;
    }
    // Upload to aws
    const didUpload = await this.uploadToAws(uploadUrl.url, file);
    if (!didUpload) {
      this.AWS_UPLOAD_FAILS.push(file);
      this.setError({
        code: "AWS_UPLOAD_FAIL",
        fileName: file.name,
        sevirity: "ERROR",
      });
      return false;
    }
    // Confirm Upload
    const didConfirm = await this.confirmUpload(
      uploadUrl.recording_id,
      fileName
    );
    if (!didConfirm) {
      this.CONFIRM_UPLOAD_FAILS.push({
        recordingId: uploadUrl.recording_id,
        file,
      });
      this.setError({
        code: "UPLOAD_CONFIRM_FAIL",
        fileName: file.name,
        sevirity: "ERROR",
      });
      return false;
    }

    // Successfule upload
    if (this.setFileUploaded) {
      this.setFileUploaded(file.name);
    }
    this.finishOneUpload();
    return true;
  }

  private async retryConfirm(recordingId: string, file: File) {
    const fileName = file.name.toLowerCase();
    const didConfirm = await this.confirmUpload(recordingId, fileName);
    if (!didConfirm) {
      this.CONFIRM_UPLOAD_FAILS.push({
        recordingId,
        file,
      });
      return false;
    }
    if (this.setFileUploaded) {
      this.setFileUploaded(file.name);
    }
    this.finishOneUpload();
    return true;
  }

  // PromisePool - use to keep just couple of active promises
  // so we don't send all http request at once and get blocked for spamming
  private async promisePool(poolSize: number, files: File[]) {
    const returns: Awaited<boolean>[] = [];
    const pool: Promise<{ index: number; val: boolean }>[] = [];
    const proccess = async (index: number, file: File) => {
      const val = await this.uploadFlow(file);
      return { index, val };
    };
    // eslint-disable-next-line guard-for-in
    for (const index in files) {
      let task = null;
      if (pool.length >= poolSize) {
        // Wait for one to finish
        // eslint-disable-next-line no-await-in-loop
        task = await Promise.race(pool);
        returns.push(task.val);
      }
      if (task) {
        // replace finished task with new task
        pool.splice(task.index, 1, proccess(task.index, files[index]));
      } else {
        // add task
        pool.push(proccess(+index, files[index]));
      }
    }
    // Await for rest of the pool
    const restOfPool = await Promise.all(pool);

    for (const rest of restOfPool) {
      returns.push(rest.val);
    }
    return returns;
  }

  private static validateFileName(fileName: string) {
    const parts = fileName.toLowerCase().split(".");
    if (parts.length !== 2 || parts[1] !== "wav") return false;
    return dayjs(parts[0], "YYYYMMDD_HHmmss", true).isValid();
  }

  private finishOneUpload() {
    this.uploadedCounter += 1;
    if (this.setProgress) {
      this.setProgress(
        this.NUMBER_OF_FILES ? this.uploadedCounter / this.NUMBER_OF_FILES : 1
      );
    }
  }

  private async getUploadUrl(fileName: string) {
    const uploadUrlRes = await axios.request<UploadUrlRes>({
      url: `${REACT_APP_API_URL}/recording-sites/${this.recordingSiteId}/upload-url`,
      method: "PUT",
      data: JSON.stringify({
        name: fileName.toLowerCase(),
      }),
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    });
    if (uploadUrlRes.data.exists) {
      console.log("file already uploaded");
      this.finishOneUpload();
      return null;
    }
    return uploadUrlRes.data;
  }

  private async uploadToAws(url: string, file: File) {
    const prom = await axios.request({
      method: "PUT",
      url,
      data: file,
    });
    if (prom.status >= 400) {
      console.log("failed to upload to aws");
      this.finishOneUpload();
      return false;
    }
    return true;
  }

  private async confirmUpload(recordingId: string, fileName: string) {
    const confurmUploadRes = await axios.request<boolean>({
      url: `${REACT_APP_API_URL}/recordings/${recordingId}/confirm-upload`,
      method: "PUT",
      data: JSON.stringify({
        name: fileName,
      }),
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    });
    if (!confurmUploadRes.data) {
      console.log("fail to confirm upload");
      this.finishOneUpload();
      return false;
    }
    return true;
  }
}

export async function uploadFilesImproved(params: UploadFileParams) {
  const uploadFile = new UploadFile(params);
  return uploadFile.upload(params.files);
}
