/* eslint-disable react/no-unknown-property */
/* eslint-disable no-restricted-syntax */
import React, {
  MouseEvent,
  ChangeEvent,
  useEffect,
  useState,
  useMemo,
  AriaAttributes,
  DOMAttributes,
} from "react";
import { Box, Button, Card, Modal, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { UploadError, UploadFile } from "../../utils/uploadFiles";
import { UploadFileProps } from "../../types/PropsTypes";
import { styleUpload, styleUploadRejected } from "../../styles/customStyles";
import { playAudio } from "../../utils/playAudio";
import LinearProgressWithLabel from "../linearProgress/LinearProgressWithLabel";
import { notify } from "../../utils/notify";
import FileUploadErrorCard from "../cards/FileUploadErrorCard";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    webkitdirectory?: string;
    directory?: string;
  }
}

function AudioFileUpload({ token, recordingSiteId }: UploadFileProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<UploadError[]>([]);
  const [filesResponseStatus, setFilesResponseStatus] = useState<boolean[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const uploadFiles = useMemo(
    () =>
      new UploadFile({
        recordingSiteId,
        token,
        setProgress,
        setFileUploaded: (f) => setFilesUploaded((current) => [...current, f]),
        setError: (error: UploadError) =>
          setErrors((current) => [...current, error]),
      }),
    [setProgress, setFilesUploaded, token, recordingSiteId]
  );
  useEffect(() => {
    if (filesResponseStatus.length > 0 && !errors.length) {
      playAudio();
    }
  }, [filesResponseStatus, errors]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setErrors([]);
    setFilesUploaded([]);
    setFilesResponseStatus([]);
    setProgress(0);
    setUploading(false);
    uploadFiles.reset();
  };

  useEffect(() => {
    if (filesResponseStatus.length > 0) {
      if (errors.length === 0) {
        setTimeout(() => {
          handleClose();
          notify("Files successfully uploaded!");
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesResponseStatus.length, errors.length]);

  const handleFileChange = ({ target: { files } }: any) => {
    setFiles(Array.from(files));
    setFilesResponseStatus([]);
  };

  const handleClickChange = (event: MouseEvent) => {
    event.preventDefault();
  };

  const uploadAudioFiles = async () => {
    if (token && files.length) {
      setUploading(true);
      const filesResponses = await uploadFiles.upload(files);

      setFilesResponseStatus(filesResponses);
    }
  };
  const handleRetry = async () => {
    setUploading(true);
    setErrors([]);
    setFilesResponseStatus([]);
    setProgress(0);
    const filesResponses = await uploadFiles.retry();
    setFilesResponseStatus((current) => [...current, ...filesResponses]);
  };

  useEffect(() => {
    const folder = document.getElementById("filepicker");
    folder?.addEventListener(
      "change",
      (event: any) => {
        const output: any = document.getElementById("listing");
        for (const file of event.target.files) {
          const item = document.createElement("li");
          item.textContent = file.webkitRelativePath;
          output.appendChild(item);
        }
      },
      false
    );
  }, []);

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <UploadIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={errors.length > 0 ? styleUploadRejected : styleUpload}>
          <Box display="flex" justifyContent="space-around">
            <Box textAlign="center" position="relative">
              <CloudUploadIcon color="primary" fontSize="large" />
              <Typography variant="subtitle1">Upload your files</Typography>
              <input
                type="file"
                name="fileList"
                style={{
                  position: "absolute",
                  opacity: 0,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                multiple
                onChange={(event: ChangeEvent) => handleFileChange(event)}
                onClick={(event: MouseEvent) =>
                  uploading && handleClickChange(event)
                }
              />
            </Box>
            <Box textAlign="center" position="relative">
              <DriveFileMoveIcon color="primary" fontSize="large" />
              <Typography variant="subtitle1">Upload your folder</Typography>
              <input
                type="file"
                id="filepicker"
                name="fileList"
                webkitdirectory="true"
                directory="true"
                style={{
                  position: "absolute",
                  opacity: 0,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                multiple
                onChange={(event: ChangeEvent) => handleFileChange(event)}
                onClick={(event: MouseEvent) =>
                  uploading && handleClickChange(event)
                }
              />
            </Box>
          </Box>
          <Box mt={2} mb={2}>
            <Card sx={{ textAlign: "center", mb: "3px" }}>
              {files.length > 0 ? (
                <Box>{files.length} files selected </Box>
              ) : null}
              {filesUploaded.length > 0 || files.length > 0 ? (
                <Box>
                  Uploaded {filesUploaded.length} / {files.length} files
                </Box>
              ) : null}
            </Card>
            {errors.map((error: UploadError) => {
              return (
                <FileUploadErrorCard
                  key={error.fileName}
                  code={error.code}
                  sevirity={error.sevirity}
                  fileName={error.fileName}
                />
              );
            })}
          </Box>
          {uploading && <LinearProgressWithLabel value={progress} />}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              color="primary"
              variant="contained"
              style={{ position: "relative", zIndex: 20 }}
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
            {filesUploaded.length > 0 && errors.length > 0 ? (
              <Button
                disabled={
                  !errors.length || (!filesUploaded.length && uploading)
                }
                color="primary"
                variant="contained"
                style={{ position: "relative", zIndex: 20 }}
                type="submit"
                onClick={handleRetry}
              >
                Retry
              </Button>
            ) : (
              <Button
                disabled={!files.length || uploading}
                color="primary"
                variant="contained"
                style={{ position: "relative", zIndex: 20 }}
                type="submit"
                onClick={uploadAudioFiles}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AudioFileUpload;
