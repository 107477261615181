import React, { ChangeEvent, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
import {
  OutlinedInput,
  InputAdornment,
  TableSortLabel,
  TableFooter,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import {
  DataResponseType,
  ErrorTypes,
  Order,
  PaginationWithSort,
} from "../../types/DataTypes";
import { TABLE_HEADERS_USERS } from "../../constants/tableConstants";
import useDebounce from "../../hooks/useDebounce";
import "../../styles/tableCell.css";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { FarmSelectionObject } from "../../types/PropsTypes";
import { getRoleName } from "../../utils/getRoleName";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import InputAutoComplete from "../../components/input/InputAutoComplete";
import { OrderEnum } from "../../constants/enums";
import useMutate from "../../service/useMutate";
import { notify } from "../../utils/notify";

function UserListPage() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [sort, setSort] = useState<string>("");
  const [farm, setFarm] = useState<string>("");
  const { token } = useAuth();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestHeader: any = useMemo(() => {
    return { Authorization: `Bearer ${token}` };
  }, [token]);
  const { loading: verificationLoading, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/auth/reset-verification`,
    method: "PUT",
    headers: requestHeader,
  });

  const { responseData: farmsData } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData, loading, error } = useFetch<
    PaginationWithSort<DataResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/users?page=${page}&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&search=${search}&farm=${farm}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  useDebounce(() => {
    setSearch(debouncedValue);
  }, debouncedValue);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setDebouncedValue(event.target.value);
    setPage(0);
  };

  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };

  const handleNavigate = (id: string) => {
    navigate(`/users/${id}`);
  };

  const handleNavigateAddUser = () => {
    navigate("/users/create");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResendVerificationLink = (email: string, event: any) => {
    /**
     * prevent raw click to happen,
     * which will cause navigation to edit user page
     */
    event.stopPropagation();

    fetchData({ email })
      .then(() => {
        notify(`Invitation resent for user ${email}.`);
      })
      .catch((error: ErrorTypes) => {
        notify(`Resend failed for user ${email}!`, error);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: any) => {
    setFarm(newValue?.id ?? "");
    setPage(0);
  };
  return (
    <Container>
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <BackComponent />
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      <Grid container spacing={0} direction="column">
        <Grid container spacing={1}>
          <Grid item container xs={12} sm={12} md={8} lg={8} spacing={1}>
            <Grid item pb={2} xs={12} sm={12} md={6} lg={6}>
              <OutlinedInput
                fullWidth
                id="search"
                name="search"
                placeholder="Search user..."
                type="text"
                size="small"
                onChange={handleSearch}
                value={debouncedValue}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item pb={2} xs={12} sm={12} md={6} lg={6}>
              <InputAutoComplete
                value={null}
                label="Farm"
                onChange={handleChange}
                options={farmsData ?? []}
                getOptionLabel={(farm) =>
                  (farm as FarmSelectionObject).name ?? ""
                }
              />
            </Grid>
          </Grid>
          <Grid item pb={2} xs={12} sm={12} md={4} lg={4}>
            <Button
              fullWidth
              type="button"
              size="medium"
              variant="contained"
              onClick={handleNavigateAddUser}
            >
              Add user
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
          <TableContainer component={Paper}>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <Table sx={{ minWidth: 600 }} aria-label="table">
                  <TableHead>
                    <TableRow>
                      {TABLE_HEADERS_USERS.map(({ sortName, label }) => (
                        <TableCell
                          key={label}
                          onClick={() => handleSort(sortName)}
                          sx={{ cursor: "pointer", paddingLeft: "100px" }}
                          align="left"
                        >
                          {label}
                          {sortName && (
                            <TableSortLabel
                              active={sort === sortName}
                              direction={sort === sortName ? order : "asc"}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(loading || verificationLoading) && !responseData && (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {responseData?.results.map((data: DataResponseType) => (
                      <TableRow
                        hover
                        onClick={() => handleNavigate(data.id)}
                        sx={{
                          opacity: data.is_active === false ? "0.38" : "none",
                          cursor: "pointer",
                          "&.MuiTableRow-root:hover": {
                            backgroundColor: "rgba(206, 133, 20, 0.12)",
                          },
                        }}
                        key={data.id}
                      >
                        <TableCell sx={{ paddingLeft: "100px" }} align="left">
                          {data.name}
                        </TableCell>
                        <TableCell sx={{ paddingLeft: "100px" }} align="left">
                          {data.email}
                        </TableCell>
                        <TableCell sx={{ paddingLeft: "100px" }} align="left">
                          {getRoleName(data.role)}
                        </TableCell>
                        <TableCell
                          sx={{ paddingLeft: "100px" }}
                          align="left"
                          unselectable="on"
                        >
                          {!data.is_verified && (
                            <Button
                              type="button"
                              size="medium"
                              variant="contained"
                              onClick={(event) =>
                                handleResendVerificationLink(data.email, event)
                              }
                            >
                              Resend Invitation
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <PaginationComponent
                    count={responseData?.metadata.count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </Table>
              </Box>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
export default UserListPage;
