import React from "react";
import Typography from "@mui/material/Typography";
import { TitleProps } from "../../types/PropsTypes";

function TitleComponent({ title, variant, align, sx }: TitleProps) {
  return (
    <Typography sx={sx} variant={variant} align={align}>
      {title}
    </Typography>
  );
}
export default TitleComponent;
