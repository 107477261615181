import React, { useEffect } from "react";
import {
  Box,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import { watersList } from "./helpers";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";

export default function NaturalWater({ name }: { name: string }) {
  const [field, meta, helpers] = useField(name);
  const getWaterTypeKey = (waterType: string) => {
    return waterType.split(" ").splice(-1)[0].toLowerCase();
  };
  useEffect(() => {
    // initially set all waters to false
    if (areAllPropertiesEmpty(field.value)) {
      const defaultWaters = watersList.reduce((acc, val) => {
        const waterKey = getWaterTypeKey(val);
        return { ...acc, [waterKey]: false };
      }, {});
      helpers.setValue(defaultWaters);
    }
  }, [field.value, helpers]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle1">
        Is there natural water on this farm?
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}> Water Type </TableCell>
              <TableCell align="center"> Yes</TableCell>
              <TableCell align="center"> No </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {watersList.map((waterType: string) => {
              const waterTypeKey = getWaterTypeKey(waterType);
              return (
                <TableRow key={waterTypeKey}>
                  <TableCell colSpan={2}>{waterType}</TableCell>
                  <TableCell colSpan={2} align="center">
                    <RadioGroup
                      {...field}
                      onChange={(e) =>
                        helpers.setValue({
                          ...field.value,
                          [waterTypeKey]: Boolean(e.target.value),
                        })
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-around"
                      >
                        <Radio
                          value="true"
                          size="small"
                          checked={field.value[waterTypeKey]}
                        />
                        <Radio
                          value=""
                          size="small"
                          checked={!field.value[waterTypeKey]}
                        />
                      </Box>
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Box>
  );
}
