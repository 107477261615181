import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";

import Button from "@mui/material/Button/Button";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { DataResponseType } from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";
import { getRoleName } from "../../utils/getRoleName";

function UserProfilePage() {
  const { token } = useAuth();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestHeader: any = useMemo(() => {
    return { Authorization: `Bearer ${token}` };
  }, [token]);
  const { responseData, loading, error } = useFetch<DataResponseType>({
    url: `${REACT_APP_API_URL}/users/me`,
    method: "GET",
    headers: requestHeader,
  });

  const handleNavigateChangePass = () => {
    navigate("/user/change-password");
  };
  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {responseData && (
          <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <Box textAlign="center">
                <Box display="flex">
                  <Box flexGrow={1} pl={5}>
                    <CardHeader title={responseData.name} />
                  </Box>
                </Box>
                <Divider />
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    Email: {responseData.email}
                  </Typography>
                  <Typography gutterBottom variant="h6">
                    Role: {getRoleName(responseData.role)}
                  </Typography>
                  <Box textAlign="right" pt={3}>
                    <Button
                      onClick={handleNavigateChangePass}
                      variant="contained"
                    >
                      Change password
                    </Button>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default UserProfilePage;
