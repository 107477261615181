import React, { ChangeEvent } from "react";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import InputFieldFormik from "./InputFieldFormik";

function LandUsage({ name }: { name: string }) {
  const inputProps = {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
    min: 0,
    max: 100,
  };
  const [field, meta, helpers] = useField(name);
  const handleChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    const value = +e.target.value || "";
    helpers.setValue({
      ...field.value,
      [name]: value,
    });
  };
  return (
    <Grid container mt={2} mb={2} columnSpacing={1} spacing={2}>
      <Grid item sm={12}>
        <Typography variant="subtitle1">
          What percent of the land is dedicated to the following?
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.natural_grass}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "natural_grass")
          }
          label="Natural grass (rough grazing)"
          InputProps={inputProps}
          size="small"
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.grass_seed_mix}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "grass_seed_mix")
          }
          label="Grass seed mix"
          type="number"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.planted_herbal_ley}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "planted_herbal_ley")
          }
          label="Planted herbal ley"
          type="number"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.silvopasture}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "silvopasture")
          }
          label="Silvopasture (woodland with leaves for browsing)"
          type="number"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.forage_or_crops}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "forage_or_crops")
          }
          label="Forage and/or maize crops"
          type="number"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.arable}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "arable")
          }
          label="Arable"
          type="number"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={field.value.left_for_nature}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "left_for_nature")
          }
          type="number"
          label="Land left for nature"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6} width="100%">
        <TextField
          value={+field.value.other}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, "other")
          }
          type="number"
          label="Other"
          InputProps={inputProps}
          size="small"
          fullWidth
        />
      </Grid>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Grid>
  );
}

export default LandUsage;
