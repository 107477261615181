import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import WebSiteLink from "@mui/material/Link";
import ReportCard from "../../../components/cards/ReportCard";
import { handleNavigateWebsite } from "../../../utils/handleNavigateWebsite";
import { SpeciesReportType } from "../../../types/DataTypes";
import { getColor } from "./ChartColors/ChartColors";

export default function SpeciesDetails({
  species,
  onClick,
}: {
  species: SpeciesReportType[];
  onClick: () => void;
}) {
  return (
    <ReportCard
      title="Species Details"
      sx={{ height: { md: "60%" }, minHeight: "auto" }}
    >
      {species
        ?.slice(0, 6)
        .map(
          (
            {
              latin: latinName,
              english: englishName,
              song_url: songURL,
              is_endangered: isEndangered,
            }: SpeciesReportType,
            index: number
          ) => {
            return (
              <Stack
                key={latinName}
                direction="row"
                gap={2}
                py={0.5}
                alignItems="center"
              >
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                    borderRadius: 12,
                    background: getColor(index),
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: isEndangered ? "#FF0000" : "primary",
                  }}
                >
                  {songURL ? (
                    <WebSiteLink
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleNavigateWebsite(songURL)}
                    >
                      {englishName}
                    </WebSiteLink>
                  ) : (
                    englishName
                  )}
                </Typography>
              </Stack>
            );
          }
        )}
      <Stack direction="row" gap={2} py={0.5} alignItems="center">
        <Box
          sx={{
            height: 24,
            width: 24,
            borderRadius: 12,
            background: getColor(6),
          }}
        />
        <WebSiteLink
          variant="h6"
          sx={{
            cursor: "pointer",
            color: "inherit",
          }}
          onClick={onClick}
        >
          Full list of species ↓
        </WebSiteLink>
      </Stack>
    </ReportCard>
  );
}
