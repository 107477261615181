/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable spaced-comment */
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  Card,
  Chip,
} from "@mui/material";
import { useCallback, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { styleModal } from "../../styles/customStyles";
import { CalendarModalPropsType } from "../../types/PropsTypes";
import { ResponseRecordingDates } from "../../types/DataTypes";

function CalendarModal({ responseData, id }: CalendarModalPropsType) {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Dayjs | null>(null);

  const { token } = useAuth();
  const { responseData: dates } = useFetch<ResponseRecordingDates>({
    url: `${REACT_APP_API_URL}/farms/${id}/recording-sites/${responseData?.id}/dates`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getDayElement = useCallback(
    (
      day: Dayjs,
      selectedDays: Dayjs[],
      pickersDayProps: PickersDayProps<Dayjs>
    ) => {
      const isProcessed =
        dates?.processed_dates &&
        dates?.processed_dates.includes(day.utc().format("YYYY-MM-DD"));

      const isUploaded =
        dates?.date && dates?.date.includes(day.utc().format("YYYY-MM-DD"));

      const getColor = () => {
        if (isProcessed) return "#2e7d32";
        if (isUploaded) return "primary";
        return null;
      };

      return (
        <PickersDay
          sx={{
            "&.MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: getColor,
              },
            },
          }}
          {...pickersDayProps}
          selected={isUploaded || isProcessed}
        />
      );
    },
    [dates]
  );
  return (
    <>
      <Grid item>
        <IconButton onClick={handleOpen}>
          <CalendarMonthIcon fontSize="medium" color="action" />
        </IconButton>
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              reduceAnimations
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              renderDay={getDayElement}
            />
          </LocalizationProvider>
          <Box>
            {dates?.uploaded.map((file) => {
              return (
                <Box pt={1} key={file.n}>
                  <Card
                    sx={{ border: "1px solid #CE8514", borderRadius: "20px" }}
                    elevation={3}
                  >
                    <Box p={1} display="flex" flexDirection="column" gap={1}>
                      <Typography
                        gutterBottom
                        fontWeight="600"
                        color="primary"
                        textAlign="center"
                      >
                        Year {file.year}
                      </Typography>
                      <Chip
                        size="small"
                        label={`Uploaded files: ${file.n}`}
                        color="primary"
                      />
                      <Chip
                        size="small"
                        label={`Processed files: ${file.p}`}
                        color="success"
                      />
                      <Chip
                        size="small"
                        label={`Failed files: ${file.f}`}
                        color="error"
                      />
                    </Box>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
export default CalendarModal;
