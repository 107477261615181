import React from "react";
import Button from "@mui/material/Button";
import { ButtonPropsTypes } from "../../types/PropsTypes";

function ButtonComponent({
  type,
  title,
  children,
  onClick,
  variant,
  color,
  fullWidth,
  size,
}: ButtonPropsTypes) {
  return (
    <Button
      type={type}
      onClick={onClick}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
    >
      {children}
      {title}
    </Button>
  );
}
export default ButtonComponent;
