import { useState, useEffect } from "react";
import axios from "axios";
import { Props } from "../types/PropsTypes";
import { interceptAxios } from "./interceptAxios";
import { useAuth } from "../context/AuthContext";
import { ApiResponseTypeFetch, ErrorTypes } from "../types/DataTypes";

const defaultHeader = { "Content-Type": "application/json" };
function useFetch<T = unknown>({
  url,
  method,
  headers,
  refresh,
  enabled = true,
  onSuccess,
}: Props): ApiResponseTypeFetch<T> {
  const [responseData, setResponseData] = useState<T>();
  const [error, setError] = useState<ErrorTypes | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { logout } = useAuth();
  useEffect(() => {
    if (!enabled) return;
    setLoading(true);
    interceptAxios(logout);
    axios(url, {
      method,
      headers: headers ? { ...defaultHeader, ...headers } : defaultHeader,
    })
      .then((res) => {
        setResponseData(res.data);
        onSuccess?.(res.data);
      })
      .catch((err) => {
        setError(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, headers, refresh, enabled]);

  return { responseData, loading, error };
}

export default useFetch;
