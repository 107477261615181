import React, { useState } from "react";

import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper/Paper";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import Table from "@mui/material/Table/Table";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Box, Container, Divider, Typography } from "@mui/material";
import { PathParams } from "../../types/PropsTypes";
import { ResponseRecordingSite } from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import TitleComponent from "../../components/title/TitleComponent";
import TableCellWithIcon from "../../components/tableCell/TableCellWithIcon";
import { requestHeaderToken } from "../../utils/requestHeaderToken";

function ListRecordingSitePage() {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();

  const { responseData, loading, error } = useFetch<ResponseRecordingSite[]>({
    url: `${REACT_APP_API_URL}/farms/${id}/recording-sites`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return (
    <Container>
      <div>
        {error ? (
          <Box sx={{ border: 1, borderColor: "error.main" }}>
            <Typography variant="h5" p={3} textAlign="center" color="error">
              An error occurred while retrieving recording sites
            </Typography>
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Box p={2} sx={{ textAlign: "center" }}>
              <TitleComponent variant="h6" title="Recording sites" />
            </Box>
            <Divider />
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Location longitude</TableCell>
                  <TableCell>Location latitude</TableCell>
                  <TableCell>Device id</TableCell>
                  <TableCell>Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && !responseData && (
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {responseData &&
                  responseData.map((data: ResponseRecordingSite) => {
                    return (
                      <TableRow key={data.id}>
                        <TableCell>{data.location.longitude}</TableCell>
                        <TableCell>{data.location.latitude}</TableCell>
                        <TableCell>{data.memory_card_id}</TableCell>

                        <TableCellWithIcon
                          path={`/farms/${id}/recording-sites/${data.id}/report`}
                        >
                          <SummarizeIcon fontSize="large" color="action" />
                        </TableCellWithIcon>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Container>
  );
}
export default ListRecordingSitePage;
