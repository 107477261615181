/* eslint-disable react/require-default-props */
import { Typography } from "@mui/material";
import ReportCard from "../cards/ReportCard";

function DescriptionComponent({ htmlString }: { htmlString: string }) {
  return (
    <ReportCard
      sx={{ margin: "10px 0", height: { md: "40%" }, minHeight: "auto" }}
    >
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: htmlString,
        }}
      />
    </ReportCard>
  );
}
export default DescriptionComponent;
