import React, { useState, ChangeEvent } from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useField } from "formik";
import { livestockList } from "./helpers";
import { theme } from "../../styles/useTheme";

export default function Livestock({ name }: { name: string }) {
  const [visibleInput, setVisibleInput] = useState<boolean>(false);
  const [newLivestock, setNewLivestock] = useState<string>("");
  const [field, meta, helpers] = useField(name);
  const [livestock, setLivestock] = useState<string[]>([
    ...new Set([...livestockList, ...Object.keys(field.value)]),
  ]);
  const toggleInput = () => {
    setVisibleInput((prevInput) => !prevInput);
  };
  const addLivestock = () => {
    if (!newLivestock) return;

    setLivestock((prevLivestock) => [...prevLivestock, newLivestock]);
    setNewLivestock("");
    toggleInput();
  };
  const handleDeleteLivestock = (livestockItem: string) => {
    setLivestock((prevLivestock) =>
      prevLivestock.filter(
        (prevLivestockItem) => prevLivestockItem !== livestockItem
      )
    );
    const { [livestockItem]: removedItem, ...restObject } = field.value;
    helpers.setValue({ ...restObject });
  };
  const isSelected = (livestockItem: string, value: string) => {
    if (!Object.keys(field.value).includes(livestockItem)) return false;
    const entries = Object.entries(field.value).flat();
    const indexOfItem = entries.indexOf(livestockItem);
    return entries[indexOfItem + 1] === value;
  };
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle1">
        What is the approximate stocking density of the livestock?
      </Typography>
      <Typography variant="body2">
        (fill in as many livestock types as the farm has)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Livestock</TableCell>
              <TableCell align="center">
                More per hectare than the average farm
              </TableCell>
              <TableCell align="center">Similar to the average farm</TableCell>
              <TableCell align="center">
                Less per hectare than the average farm
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {livestock.map((livestockItem: string) => (
              <TableRow key={livestockItem}>
                <TableCell>{livestockItem}</TableCell>
                <TableCell colSpan={3}>
                  <RadioGroup
                    {...field}
                    onChange={(e) =>
                      helpers.setValue({
                        ...field.value,
                        ...{ [livestockItem]: e.target.value },
                      })
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-around"
                    >
                      <Radio
                        value="more"
                        size="small"
                        checked={isSelected(livestockItem, "more")}
                      />
                      <Radio
                        value="similar"
                        size="small"
                        checked={isSelected(livestockItem, "similar")}
                      />
                      <Radio
                        value="fewer"
                        size="small"
                        checked={isSelected(livestockItem, "fewer")}
                      />
                    </Box>
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDeleteLivestock(livestockItem)}
                    key={livestockItem}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                <Button onClick={toggleInput}>
                  {visibleInput ? "Close" : "Add more"}
                  {visibleInput ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <AddIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell colSpan={3}>
                {visibleInput && (
                  <TextField
                    label="Livestock"
                    value={newLivestock}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNewLivestock(e.target.value)
                    }
                    onKeyDown={(e) => {
                      if (e.key !== "Enter") return;
                      e.preventDefault();
                      addLivestock();
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={addLivestock}
                            style={{
                              color: theme.palette.primary.main,
                            }}
                          >
                            <Typography variant="body1">Add option</Typography>
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Box>
  );
}
