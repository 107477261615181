import { useParams } from "react-router";
import Paper from "@mui/material/Paper/Paper";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import Table from "@mui/material/Table/Table";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {
  Box,
  Container,
  Divider,
  TableFooter,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { PathParams } from "../../types/PropsTypes";
import {
  DataFarmsResponseType,
  PaginationWithSort,
  ShareRequestsResponseType,
} from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import TitleComponent from "../../components/title/TitleComponent";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import ShareRequestComponent from "../../components/shareRequestComponent/ShareRequestComponent";
import {
  TABLE_HEADERS_SHARE_REQUEST,
  TABLE_HEADERS_SHARE_REQUEST_ORGANIZATION,
} from "../../constants/tableConstants";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";

function ListShareRequestsTable({ path }: { path: string }) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [refresh, setRefresh] = useState(Math.random());
  const { token } = useAuth();
  const { id } = useParams<PathParams>();

  const farmOrOrg = path === "organization" ? "organization" : "farm";
  const {
    responseData: farmOrOrgData,
    loading: farmOrOrgLoading,
    error: errorFarmOrOrg,
  } = useFetch<DataFarmsResponseType>({
    url: `${REACT_APP_API_URL}/${path}/my-${farmOrOrg}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(!id),
  });

  const requestId = id || farmOrOrgData?.id;
  const { responseData, loading, error } = useFetch<
    PaginationWithSort<ShareRequestsResponseType>
  >({
    url: `${REACT_APP_API_URL}/${path}/${requestId}/share-requests?page=${page}&limit=${rowsPerPage}`,
    method: "GET",
    headers: requestHeaderToken(token),
    refresh,
    enabled: Boolean(requestId),
  });

  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };
  return (
    <div key={refresh}>
      {path === "farms" ? <BackComponent /> : null}
      {error || errorFarmOrOrg ? (
        <Box sx={{ border: 1, borderColor: "error.main" }}>
          <Typography variant="h5" p={3} textAlign="center" color="error">
            An error occurred while retrieving share requests
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Box p={2} sx={{ textAlign: "center" }}>
            <TitleComponent
              variant="h6"
              title="Requests for sharing report data"
            />
          </Box>
          <Divider />
          <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {path === "organization"
                      ? TABLE_HEADERS_SHARE_REQUEST_ORGANIZATION.map(
                          ({ label }) => {
                            return (
                              <TableCell key={label} align="center">
                                {label}
                              </TableCell>
                            );
                          }
                        )
                      : TABLE_HEADERS_SHARE_REQUEST.map(({ label }) => {
                          return (
                            <TableCell key={label} align="center">
                              {label}
                            </TableCell>
                          );
                        })}
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(loading || farmOrOrgLoading) && !responseData && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    (!responseData ||
                      areAllPropertiesEmpty(responseData.results)) && (
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          There are no current requests for data sharing.
                        </TableCell>
                      </TableRow>
                    )}
                  {responseData?.results.map(
                    (data: ShareRequestsResponseType) => {
                      return (
                        <TableRow key={data.id}>
                          <ShareRequestComponent
                            refreshRecordingSiteList={refreshRecordingSiteList}
                            data={data}
                            path={path}
                          />
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
                <PaginationComponent
                  count={responseData?.metadata.count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Table>
            </Box>
          </Box>
        </TableContainer>
      )}
    </div>
  );
}
export default ListShareRequestsTable;
