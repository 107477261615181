import React, { useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import { Box, CircularProgress, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import logo from "../../assets/images/chirLogo.png";
import { LoginResponse } from "../../types/DataTypes";
import ButtonComponent from "../../components/button/ButtonComponent";
import TitleComponent from "../../components/title/TitleComponent";
import InputComponent from "../../components/input/InputField";
import { validationSchemaLogin } from "../../validation/validationSchemaLogin";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useMutate from "../../service/useMutate";
import { useAuth } from "../../context/AuthContext";
import InputVisibilityPassword from "../../components/input/InputVisibilityPassword";

function LoginPage() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setToken } = useAuth();
  const { email } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const redirectEmail = email?.includes("@") ? email : "";

  const { loading, error, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/auth`,
    method: "POST",
  });
  const formik = useFormik({
    initialValues: {
      email: redirectEmail || "",
      password: "",
    },
    validationSchema: validationSchemaLogin,
    onSubmit: (values) => {
      fetchData(values).then((data: LoginResponse) => {
        if (data) {
          setToken(data.token);
        }
      });
    },
  });

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {!matches && (
          <Grid sx={{ textAlign: "center" }} item xs={12} md={7}>
            <img src={logo} alt="logo" height={150} width={200} />

            <TitleComponent title="Chirrup.ai" variant="h3" align="center" />
          </Grid>
        )}
        {matches && (
          <Grid item xs={12} md={7}>
            <img src={logo} alt="logo" height={250} width={300} />

            <TitleComponent title="Chirrup.ai" variant="h2" align="center" />
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          <Card elevation={3} sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <CardHeader
                title={
                  <TitleComponent title="Login" variant="h3" align="center" />
                }
              />
              <CardContent>
                <Box mb={4} mt={4}>
                  <InputComponent
                    id="email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    placeholder="Enter email..."
                    size="small"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText error>{formik.errors.email}</FormHelperText>
                  )}
                </Box>
                <Box mt={4}>
                  <InputVisibilityPassword
                    id="password"
                    placeholder="Enter password..."
                    value={formik.values.password}
                    label="Password"
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <FormHelperText error>
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </Box>
                <CardActions sx={{ p: 0, pt: 7 }}>
                  <ButtonComponent
                    type="submit"
                    title="Log in"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  />
                </CardActions>
                {error ? (
                  <Box display="flex" justifyContent="center" pt={3}>
                    <FormHelperText error>{error?.error}</FormHelperText>
                  </Box>
                ) : null}
                <Box textAlign="center" pt={6}>
                  <Link to="/user/forgot-password">
                    {" "}
                    <Typography variant="body1">
                      {" "}
                      Did you forget your password?
                    </Typography>
                  </Link>
                </Box>
                <Box display="flex" justifyContent="center" mt={3}>
                  {loading && <CircularProgress />}
                </Box>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoginPage;
