import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DropdownSelectProps } from "../../types/PropsTypes";

export default function DropdownSelect<T>({
  options,
  label,
  getText,
  getValue,
  value,
  onChange,
}: DropdownSelectProps<T>) {
  const handleChangeValue = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };
  return (
    <Box minWidth={200}>
      <FormControl sx={{ width: "100%" }} size="small">
        <InputLabel id="dropdown-select">{label}</InputLabel>
        <Select
          size="small"
          labelId="dropdown-select"
          value={value}
          label={label}
          onChange={handleChangeValue}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            MenuListProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
        >
          {options?.map((option) => {
            return (
              <MenuItem key={getValue(option)} value={getValue(option)}>
                {getText(option)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
