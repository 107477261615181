import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

export default function ChirrupRatingPopover({
  open,
  anchorElement,
}: {
  open: boolean;
  anchorElement: HTMLElement | null;
}) {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Box sx={{ p: 1 }}>
        <List>
          <Typography paddingLeft={0.5}>
            Farms receive 1, 2 or 3 Chirrup notes if any of the following apply,
            in the current year:
          </Typography>
          <ListItem disablePadding>
            <ListItemButton disableGutters>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <MusicNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Using the device on farm" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disableGutters>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <MusicNoteIcon />
              </ListItemIcon>
              <ListItemText primary="In top 25% of farms, based on the 14 day period with the most species identified" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disableGutters>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <MusicNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Higher number of species on farm, compared with previous years." />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Popover>
  );
}
