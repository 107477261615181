import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import InputFieldComponent from "../../components/input/InputField";
import useMutate from "../../service/useMutate";
import DisabledModalMessage from "../../components/modal/DisabledModalMessage";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import {
  OrganizationDataResponseType,
  ErrorTypes,
} from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";
import { notify } from "../../utils/notify";
// eslint-disable-next-line max-len
import { validationSchemaCreateOrganization } from "../../validation/validationSchemaCreateOrganization";

function OrganizationEditPage() {
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams<PathParams>();
  const { token } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } =
    useFetch<OrganizationDataResponseType>({
      url: `${REACT_APP_API_URL}/organization/${id}`,
      method: "GET",
      headers: requestHeaderToken(token),
    });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/organization/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: responseData?.name || "",
      website_url: responseData?.website_url || "",
      description: responseData?.description || "",
      is_active: responseData?.is_active || false,
    },
    validationSchema: validationSchemaCreateOrganization,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          notify("Organization successfully updated!");
          navigate("/organization");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleCheckbox = (id: string) => {
    formik.handleChange(id);
    if (responseData) responseData.is_active = !responseData.is_active;
    handleClose();
  };

  const changeLabel = formik.values.is_active
    ? "Activated organization"
    : "Deactivated organization";

  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <Box display="flex" justifyContent="center" mt={20}>
            <CircularProgress />
          </Box>
        )}
        {responseData && (
          <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center", p: 3 }}>
                  <CardHeader title="Edit organization information" />
                  <Divider />
                  <CardContent>
                    <Box sx={{ textAlign: "left" }}>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="name"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          size="small"
                          name="name"
                          label="Name"
                          value={formik.values?.name}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.name && (
                          <FormHelperText error>
                            {formik.errors.name}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="website_url"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="website_url"
                          label="Website URL"
                          value={formik.values?.website_url}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.website_url && (
                          <FormHelperText error>
                            {formik.errors.website_url}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="description"
                          multiline
                          rows={3}
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="description"
                          label="Description"
                          value={formik.values?.description}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.description && (
                          <FormHelperText error>
                            {formik.errors.description}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values?.is_active} />
                            }
                            id="check"
                            name="check"
                            label={changeLabel}
                            onClick={handleOpen}
                          />
                        </FormGroup>
                        {formik.errors.is_active && (
                          <FormHelperText error>
                            {formik.errors.is_active}
                          </FormHelperText>
                        )}
                        <DisabledModalMessage
                          name="organization"
                          checked={formik.values?.is_active}
                          open={open}
                          handleActivate={handleToggleCheckbox}
                          handleDeactivate={handleToggleCheckbox}
                          handleClose={handleClose}
                        />
                      </Box>
                    </Box>
                    <Button
                      disabled={!formik.isValid}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  </CardContent>
                </Box>
              </form>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
export default OrganizationEditPage;
