import * as Yup from "yup";

export const validationSchemaAddRecordingSite = Yup.object({
  location: Yup.object({
    latitude: Yup.number()
      .typeError("Latitude must be a number")
      .min(49, "Latitude must be greater then or equal to 49")
      .max(62, "Latitude must be less then or equal to 62")
      .required("Latitude is required"),
    longitude: Yup.number()
      .typeError("Longitude must be a number")
      .min(-8, "Longitude must be greater then or equal to -11")
      .max(3, "Longitude must be less then or equal to 3")
      .required("Longitude is required"),
  }),
  memory_card_id: Yup.string().required("Device id is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().optional(),
  is_active: Yup.bool().optional(),
});
