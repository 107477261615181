import axios from "axios";
import { parseJwt } from "../utils/parseJwt";

function isTokenExpired(tokenExpirationDate: number): boolean {
  return tokenExpirationDate * 1000 < Date.now();
}

export const interceptAxios = (logout: () => void) => {
  axios.interceptors.request.use(
    async (request) => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = parseJwt(token);
        if (isTokenExpired(decodedToken.exp)) {
          logout();
        }
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
