export default function areAllPropertiesEmpty(obj: any): boolean {
  if (!obj) return true;
  return Object.entries(obj).every(([key, value]) => {
    if (typeof value === "string") {
      return value.trim() === "";
    }
    if (typeof value === "number") {
      return value === 0;
    }
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    if (typeof value === "object" && value !== null) {
      return areAllPropertiesEmpty(value);
    }
    return false;
  });
}
