import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useField } from "formik";
import { theme } from "../../styles/useTheme";

export default function CheckboxList({
  label,
  options,
  name,
}: {
  label: string;
  options: string[];
  name: string;
}) {
  const [checkedOther, setCheckedOther] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>("");
  const [field, meta, helpers] = useField(name);
  const [combinedOptions, setCombinedOptions] = useState([
    ...new Set([...options, ...field.value]),
  ]);
  const handleChecking = () => {
    setCheckedOther((prev) => !prev);
  };
  const shouldBeChecked = (option: string) => {
    return field.value.includes(option);
  };
  const addCheckbox = () => {
    if (!otherValue) return;
    setCombinedOptions((prevOptions) => [...prevOptions, otherValue]);
    helpers.setValue([...field.value, otherValue]);
    setOtherValue("");
    handleChecking();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setOtherValue(e.target.value);
  const handleKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    e.preventDefault();
    addCheckbox();
  };
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {combinedOptions.map((option) => (
          <FormControlLabel
            key={option}
            control={<Checkbox value={option} />}
            label={option}
            checked={shouldBeChecked(option)}
            {...field}
          />
        ))}
        <FormControlLabel
          control={
            <Checkbox checked={checkedOther} onChange={handleChecking} />
          }
          label="Other"
        />
        {checkedOther && (
          <TextField
            label="Other"
            size="small"
            value={otherValue}
            onChange={handleChange}
            onKeyDown={handleKeydown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={addCheckbox}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant="body1">Add option</Typography>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </FormGroup>
    </FormControl>
  );
}
