/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable new-cap */
import Grid from "@mui/material/Grid/Grid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Link from "@mui/material/Link/Link";
import { PdfComponentSpeciesListPropsType } from "../../types/PropsTypes";

function PdfComponentSpeciesList({
  content,
  data,
  setVisible,
}: PdfComponentSpeciesListPropsType) {
  const savePdf = () => {
    setVisible(data.report.species.length);
    setTimeout(async () => {
      if (content) {
        const listData = await html2canvas(content, {
          scale: 2,
        });
        const imgWidth = 400;
        const pageHeight = 477;
        const imgHeight = ((listData.height * imgWidth) / 2454) * 1.24;
        let heightLeft = imgHeight;
        const imgData = listData.toDataURL("image/png");

        const pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: [400, 477],
        });

        let position = 0;
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight + 7);
          heightLeft -= pageHeight;
        }
        pdf.save("Species list.pdf");
      }
    });
  };

  return (
    <Grid
      item
      md={12}
      sm={12}
      xs={12}
      p={2}
      sx={{ display: { xs: "none", xl: "block", sm: "block" } }}
    >
      <Link component="button" variant="body2" onClick={savePdf}>
        Save as PDF
      </Link>
    </Grid>
  );
}

export default PdfComponentSpeciesList;
