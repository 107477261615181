import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          height: 0,
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#CE8514",
      light: "#F9D793",
      dark: "#8D5C25",
    },
    secondary: {
      main: "#F5B61F",
      light: "#F9D793",
      dark: "#8D5C25",
    },
  },
  typography: {
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h3: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
  },
});
