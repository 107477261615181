import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import ReportCard from "../../../components/cards/ReportCard";
import { RecordingSitesType } from "../../../types/DataTypes";

export default function RecordingSitesBasicInfo({
  recordingSites,
}: {
  recordingSites: RecordingSitesType[];
}) {
  const singleSiteData = ({
    name,
    location,
    what3words,
  }: RecordingSitesType) => {
    const { latitude, longitude } = location;
    return (
      <>
        <Typography variant="h6">{name}</Typography>
        <Link
          href={`http://maps.google.com/?q=${latitude},${longitude}`}
          target="_blank"
        >
          <Typography variant="h6">
            GRID: {location.latitude} /{location.longitude}
          </Typography>{" "}
        </Link>
        {/* <Typography variant="h6">&#x2F;&#x2F;&#x2F;: {what3words}</Typography> */}
      </>
    );
  };
  const multipleSitesData = () => {
    return (
      <Typography variant="h6">
        {recordingSites.map((recordingSite) => {
          const {
            location: { latitude, longitude },
            name,
          } = recordingSite;
          return (
            <Box component="span" key={name}>
              <Link
                href={`http://maps.google.com/?q=${latitude},${longitude}`}
                target="_blank"
              >
                {name}
              </Link>{" "}
            </Box>
          );
        })}
      </Typography>
    );
  };
  const content =
    recordingSites.length > 1
      ? multipleSitesData()
      : singleSiteData(recordingSites[0]);

  return (
    <ReportCard>
      <Typography variant="h6">Recording site(s):</Typography>
      <Divider />
      {content}
    </ReportCard>
  );
}
