import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import FetchComponent from "../../components/fetchComponent/FetchComponent";
import { DataFarmsResponseType } from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import { userRole } from "../../constants/userRoleConstants";

export default function BasicFarmInformation({
  data,
}: {
  data: DataFarmsResponseType;
}) {
  const { user } = useAuth();
  const {
    id,
    CPH_number: CPHNumber,
    SBI_number: SBINumber,
    address,
    county,
    post_code: postCode,
    benchmark_group: benchmarkGroup,
    main_interest: mainInterest,
  } = data;
  const formattedMainInterest = mainInterest
    ? (mainInterest.charAt(0).toUpperCase() + mainInterest.slice(1)).replaceAll(
        "-",
        " "
      )
    : "";
  return (
    <Box mt={3}>
      <Accordion elevation={3} defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: 0 }}
        >
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="h6" flexGrow={2}>
              Basic information
            </Typography>
            {user?.role !== userRole.organization_admin && (
              <Box alignSelf="flex-end">
                <Link to={`/farms/edit/basic-info/${id}`}>
                  <EditIcon fontSize="small" color="action" />
                </Link>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={2}>
            <Typography gutterBottom variant="body1">
              County: {county}
            </Typography>
            {user?.role &&
              {
                [userRole.admin]: (
                  <Box>
                    {benchmarkGroup ? (
                      <Typography gutterBottom variant="body1">
                        Benchmark groups:
                        <FetchComponent
                          id={benchmarkGroup}
                          path="benchmark-groups"
                        />
                      </Typography>
                    ) : null}
                  </Box>
                ),
              }[user.role]}
            <Typography gutterBottom variant="body1">
              Address: {address}
            </Typography>
            <Typography gutterBottom variant="body1">
              Post code: {postCode}
            </Typography>
            {CPHNumber ? (
              <Typography gutterBottom variant="body1">
                CPH number: {CPHNumber}
              </Typography>
            ) : null}
            {SBINumber ? (
              <Typography gutterBottom variant="body1">
                SBI number: {SBINumber}
              </Typography>
            ) : null}
            {formattedMainInterest ? (
              <Typography gutterBottom variant="body1">
                Main interest: {formattedMainInterest}
              </Typography>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
