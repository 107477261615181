/* eslint-disable max-len */
import { useState } from "react";
import { Box, Button, FormHelperText, Modal, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import { useFormik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ButtonComponent from "../button/ButtonComponent";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { ErrorTypes } from "../../types/DataTypes";
import { styleModal } from "../../styles/customStyles";
import { notify } from "../../utils/notify";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import useFetch from "../../service/useFetch";
import { FarmSelectionObject } from "../../types/PropsTypes";
import InputAutoComplete from "../input/InputAutoComplete";
import { validationSchemaCreateFarmInBenchmarkGroup } from "../../validation/validationSchemaCreateFarmInBencmarkGroup";

function AddFarmToBGModal({
  refreshRecordingSiteList,
  id,
}: {
  refreshRecordingSiteList: () => void;
  id: string | undefined;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [farmId, setFarmId] = useState<string>("");
  const { token } = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { responseData: farmsData } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { error, loading, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${farmId}/benchmark-groups/${id}/add`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      farm: "",
    },
    validationSchema: validationSchemaCreateFarmInBenchmarkGroup,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          refreshRecordingSiteList();
          notify("Farm successfully added!");
          setOpen(false);
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
          setOpen(false);
        });
    },
  });
  const handleChangeFarm = (event: any, newValue: any) => {
    formik.setFieldValue("farm", newValue?.id ?? "");
    setFarmId(newValue?.id);
  };
  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Button
        sx={{ width: "35%" }}
        type="button"
        size="medium"
        variant="contained"
        onClick={handleOpen}
      >
        Add farm
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <Typography
            textAlign="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add farm to benchmark group
          </Typography>
          {error && (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="body1" color="error">
                {error?.statusCode} {"  "}
                {error?.error} {"  "}
                {error?.message}
              </Typography>
            </Box>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Box mb={4} mt={4}>
              <InputAutoComplete
                value={null}
                onChange={handleChangeFarm}
                label="Farm"
                options={farmsData ?? []}
                getOptionLabel={(farm) =>
                  (farm as FarmSelectionObject).name ?? ""
                }
              />
              {formik.errors.farm && (
                <FormHelperText error>{formik.errors.farm}</FormHelperText>
              )}
            </Box>

            <ButtonComponent
              type="submit"
              title="Submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            />
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default AddFarmToBGModal;
