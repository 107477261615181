import { useState } from "react";
import { useFormik } from "formik";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  FormHelperText,
  CardActions,
  CircularProgress,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ButtonComponent from "../../components/button/ButtonComponent";
import TitleComponent from "../../components/title/TitleComponent";
import InputComponent from "../../components/input/InputField";
import logo from "../../assets/images/chirLogo.png";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useMutate from "../../service/useMutate";
import { ErrorTypes } from "../../types/DataTypes";
import { validationSchemaForgotPassword } from "../../validation/validationSchemaForgotPassword";
import { notify } from "../../utils/notify";

function ForgotPassword() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { loading, error, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/auth/forgot-password`,
    method: "PUT",
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchemaForgotPassword,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          setIsSubmitted(true);
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {!matches && (
          <Grid sx={{ textAlign: "center" }} item xs={12} md={7}>
            <img src={logo} alt="logo" height={150} width={200} />

            <TitleComponent title="Chirrup.ai" variant="h3" align="center" />
          </Grid>
        )}
        {matches && (
          <Grid item xs={12} md={7}>
            <img src={logo} alt="logo" height={250} width={300} />

            <TitleComponent title="Chirrup.ai" variant="h2" align="center" />
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          {isSubmitted ? (
            <Box mt={5}>
              <Card elevation={3}>
                <CardContent>
                  <Box textAlign="center" p={6}>
                    <Typography variant="h6">
                      Please, check your email address!
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Card elevation={3} sx={{ p: 3 }}>
              <form onSubmit={formik.handleSubmit}>
                <CardHeader
                  title={
                    <TitleComponent
                      title="Forgot password"
                      variant="h4"
                      align="center"
                    />
                  }
                />
                <CardContent>
                  <Box mt={4}>
                    <InputComponent
                      id="email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      placeholder="Enter email..."
                      size="small"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && (
                      <FormHelperText error>
                        {formik.errors.email}
                      </FormHelperText>
                    )}
                  </Box>
                  <CardActions sx={{ p: 0, pt: 7 }}>
                    <ButtonComponent
                      type="submit"
                      title="Submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    />
                  </CardActions>
                  <Box display="flex" justifyContent="center" mt={3}>
                    <FormHelperText error>{error?.error}</FormHelperText>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={3}>
                    {loading && <CircularProgress />}
                  </Box>
                </CardContent>
              </form>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ForgotPassword;
