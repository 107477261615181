import React, { useState, useMemo, useEffect, useCallback } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Stack from "@mui/material/Stack/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import Button from "@mui/material/Button/Button";
import Alert from "@mui/material/Alert/Alert";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormControl, InputLabel, TextField } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import useMutate from "../../service/useMutate";
import FarmReportCard from "./FarmReportCard";
import {
  ActiveReportDataType,
  Best14Days,
  FarmReportResponseType,
  ReportTime,
} from "../../types/DataTypes";
import LinearProgressWithLabel from "../../components/linearProgress/LinearProgressWithLabel";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { FarmSelectionObject } from "../../types/PropsTypes";
import InputAutoComplete from "../../components/input/InputAutoComplete";
import { REFETCH_INTERVAL } from "../../constants/refetchIntervalConstant";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";
import { notify } from "../../utils/notify";

dayjs.extend(utc);

function FarmsReportsPage() {
  const { token, user } = useAuth();
  const [farm, setFarm] = useState<string>("");
  const [fromDate, setFromDate] = React.useState<Dayjs>(
    dayjs(Date.now()).utc().startOf("year")
  );
  const [toDate, setToDate] = React.useState<Dayjs>(
    dayjs(Date.now()).utc().endOf("year")
  );
  const [recordingSites, setRecordingSites] = useState<string[]>([]);
  const [benchmarkGroup, setBenchmarkGroup] = useState<string>("");
  const [activeReportData, setActiveReportData] =
    useState<ActiveReportDataType | null>(null);
  const [report, setReport] = useState<FarmReportResponseType | null>(null);
  const [reportTime, setReportTime] = useState<ReportTime | null>(null);
  const [highlitedDays, setHighlitedDays] = useState<string[]>([]);
  const onlyFullAccess = Boolean(user?.role === "ORGANIZATION_ADMIN");
  const { responseData, loading, error } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered?full_access=${onlyFullAccess}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { fetchData: dates } = useMutate({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/${farm}/recording-sites/dates?${recordingSites
      .map((recordingSite) => `recording_sites=${recordingSite}`)
      .join("&")}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  // TODO: report loading and report error
  const { fetchData, loading: loadingReport } = useMutate({
    url: `${REACT_APP_API_URL}/farms/reports`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const { fetchData: fetchTime } = useMutate({
    url: `${REACT_APP_API_URL}/recordings/proccessed`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const { responseData: best14days } = useFetch<Best14Days>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/date-range?farm=${farm}&${recordingSites
      .map((recordingSite) => `recording_sites=${recordingSite}`)
      .join("&")}&year=${fromDate.get("year") || new Date().getFullYear()}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(farm && recordingSites.length > 0 && fromDate),
    onSuccess: (res) => {
      if (res.start_date) setFromDate(dayjs(res?.start_date));
      if (res.end_date) setToDate(dayjs(res?.end_date));
    },
  });
  const farmLookup = useMemo(() => {
    if (responseData)
      return Object.fromEntries(responseData.map((farm) => [farm.id, farm]));
    return {};
  }, [responseData]);

  const handleChange = (event: any, newValue: any) => {
    setFarm(newValue?.id ?? "");
    setRecordingSites([]);
  };
  const handleRSChange = (event: SelectChangeEvent) => {
    setRecordingSites(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const handleBGChange = (event: SelectChangeEvent) => {
    setBenchmarkGroup(event.target.value);
  };

  useEffect(() => {
    if (recordingSites.length > 0) {
      dates({}).then((data) => setHighlitedDays(data));
    }
  }, [dates, recordingSites, setHighlitedDays]);

  const formatDate = (date: Dayjs) => {
    const offset = date.utcOffset();
    const formattedDate = new Date(date.toISOString());
    formattedDate.setMinutes(formattedDate.getMinutes() + offset);
    return formattedDate.toISOString();
  };

  const onSubmit = () => {
    if (farm && benchmarkGroup && recordingSites?.length > 0) {
      setActiveReportData({
        farm,
        recording_sites: recordingSites,
        benchmark_group: benchmarkGroup,
        from: formatDate(fromDate),
        year: fromDate.get("year"),
        to: formatDate(toDate),
      });
      setReportTime(null);
      setReport(null);
    } else {
      setActiveReportData(null);
      setReportTime(null);
      setReport(null);
    }
  };
  const fetchCallback = useCallback(async () => {
    if (activeReportData) {
      const same = await fetchTime({
        ids: activeReportData.recording_sites,
      }).then((time) => {
        // Check if time and reportTime is same, if they they are same dont update interface
        // and dont fetch new report
        const same =
          reportTime && time.p === reportTime.p && time.ftp === reportTime.ftp;
        if (!same) setReportTime(time);
        return same;
      });
      if (!same)
        fetchData(activeReportData)
          .then(setReport)
          .catch((_err) => setReport(null));
    } else if (reportTime) {
      setReportTime(null);
      setReport(null);
    }
  }, [activeReportData, fetchTime, fetchData, reportTime]);
  // Fetch every sec
  useEffect(() => {
    fetchCallback(); // Inital fetch
    const intervalId = setInterval(async () => {
      await fetchCallback();
    }, REFETCH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [fetchCallback]);

  const getDayElement = useCallback(
    (
      day: Dayjs,
      selectedDays: Dayjs[],
      pickersDayProps: PickersDayProps<Dayjs>
    ) => {
      const isUploaded = highlitedDays.includes(
        day.utc(true).toISOString().split("T")[0]
      );
      return <PickersDay {...pickersDayProps} selected={isUploaded} />;
    },
    [highlitedDays]
  );

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {error && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <BackComponent />
            <Typography variant="h4" color="error">
              {error?.statusCode} {"  "}
              {error?.error}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2} direction="row" margin={0}>
          <Grid item xs={12}>
            {!loading && responseData && (
              <Grid spacing={2} container>
                <Grid item xs={12} md={4} lg={4}>
                  <InputAutoComplete
                    value={null}
                    label="Farm"
                    onChange={handleChange}
                    options={responseData ?? []}
                    getOptionLabel={(farm) =>
                      (farm as FarmSelectionObject).name ?? ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="recordingSites">
                      Recording sites
                    </InputLabel>
                    <Select
                      label="Recording sites"
                      labelId="recordingSites"
                      notched
                      size="small"
                      multiple
                      value={recordingSites as unknown as string}
                      fullWidth
                      onChange={handleRSChange}
                    >
                      {farm &&
                        farmLookup[farm].recording_sites?.map((rs) => {
                          return (
                            <MenuItem key={rs.id} value={rs.id}>
                              {rs.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="benchmarkGroup">
                      Benchmark groups
                    </InputLabel>
                    <Select
                      label="Benchmark groups"
                      labelId="benchmarkGroup"
                      notched
                      size="small"
                      value={benchmarkGroup}
                      fullWidth
                      onChange={handleBGChange}
                    >
                      {farm &&
                        farmLookup[farm].benchmark_groups?.map((bg) => {
                          return (
                            <MenuItem key={bg.id} value={bg.id}>
                              {bg.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <DatePicker
                    views={["year"]}
                    openTo="year"
                    label="Year"
                    value={fromDate}
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setFromDate(
                          fromDate.clone().set("year", newValue.get("year"))
                        );
                        setToDate(
                          toDate.clone().set("year", newValue.get("year"))
                        );
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <DatePicker
                    views={["day"]}
                    inputFormat="D MMM YYYY"
                    disableMaskedInput
                    label="From Date"
                    value={fromDate}
                    minDate={fromDate.startOf("year")}
                    maxDate={fromDate.endOf("year")}
                    onChange={(newValue) => {
                      if (newValue) {
                        setFromDate(newValue);
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                    renderDay={getDayElement}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <DatePicker
                    views={["day"]}
                    inputFormat="D MMM YYYY"
                    disableMaskedInput
                    label="To Date"
                    value={toDate}
                    minDate={fromDate.startOf("year")}
                    maxDate={fromDate.endOf("year")}
                    onChange={(newValue) => {
                      if (newValue) {
                        setToDate(newValue);
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                    renderDay={getDayElement}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Button
                    onClick={() => onSubmit()}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Generate report
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* ftp represents uploaded files and p represents processed files */}
          {reportTime &&
            reportTime.ftp > reportTime.p &&
            reportTime.p !== 0 &&
            report && (
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <span>Successfully processed:</span>
                  <Box sx={{ width: "80%" }}>
                    <LinearProgressWithLabel
                      value={reportTime.p / reportTime.ftp}
                    />
                  </Box>
                </Stack>
              </Grid>
            )}

          <Grid item xs={12}>
            {report && report.report.species && !loadingReport && (
              <FarmReportCard data={report} />
            )}
            {!report && !reportTime && (
              <Alert severity="info">
                Please choose farm and recording site.
              </Alert>
            )}
            {!report && reportTime && (
              <Alert severity="warning">
                {(reportTime.ftp === 0 &&
                  "This farm or recording site has no uploaded files to generate a report.") ||
                  (reportTime.p === 0 &&
                    "There are no successfully processed files in the selected date range to generate a report.") ||
                  (reportTime.p !== 0 &&
                    "There is not enough data to generate a report")}
              </Alert>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Container>
  );
}

export default FarmsReportsPage;
