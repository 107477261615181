import { Box, IconButton, Tooltip, Chip } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { userRole } from "../../constants/userRoleConstants";
import { useAuth } from "../../context/AuthContext";
import useMutate from "../../service/useMutate";
import { ErrorTypes } from "../../types/DataTypes";
import { notify } from "../../utils/notify";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { ShareRequestActionsPropsType } from "../../types/PropsTypes";

function ShareRequestActions({
  id,
  refreshRecordingSiteList,
  status,
}: ShareRequestActionsPropsType) {
  const { token, user } = useAuth();
  const { fetchData: revokeFetchData } = useMutate({
    url: `${REACT_APP_API_URL}/share-requests/${id}/revoke`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const { fetchData: acceptFetchData } = useMutate({
    url: `${REACT_APP_API_URL}/share-requests/${id}/approve`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const { fetchData: rejectFetchData } = useMutate({
    url: `${REACT_APP_API_URL}/share-requests/${id}/reject`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const { fetchData: acceptLimitedFetchData } = useMutate({
    url: `${REACT_APP_API_URL}/share-requests/${id}/approve-limited`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const handleRequest = (
    id: string,
    fetchData: (data: any) => Promise<any>,
    message: string
  ) => {
    fetchData({ id })
      .then(() => {
        refreshRecordingSiteList();
        notify(`Request ${message}!`);
      })
      .catch((error: ErrorTypes) => {
        notify("Request failed!", error);
      });
  };
  if (!user) return null;
  if (status !== "PENDING") return null;

  if (user.role === userRole.organization_admin)
    return (
      <Box>
        <Tooltip title="Cancel request" arrow>
          <IconButton
            onClick={() => handleRequest(id, revokeFetchData, "canceled")}
          >
            <UndoIcon fontSize="medium" color="action" />
          </IconButton>
        </Tooltip>
      </Box>
    );
  if (user.role === userRole.farm_admin)
    return (
      <Box>
        <Box display="flex" justifyContent="space-around">
          <Tooltip title="Share all data" arrow>
            <IconButton
              onClick={() => handleRequest(id, acceptFetchData, "accepted")}
            >
              <DoneAllIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share species diversity data" arrow>
            <IconButton
              onClick={() =>
                handleRequest(id, acceptLimitedFetchData, "limited accepted")
              }
            >
              <DoneIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share species diversity data anonymously" arrow>
            <IconButton
              onClick={() => handleRequest(id, rejectFetchData, "declined")}
            >
              <ClearIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  if (user.role === userRole.admin)
    return (
      <Box>
        <Box display="flex" justifyContent="space-around">
          <Tooltip title="Share all data" arrow>
            <IconButton
              onClick={() => handleRequest(id, acceptFetchData, "accepted")}
            >
              <DoneAllIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share species diversity data" arrow>
            <IconButton
              onClick={() =>
                handleRequest(id, acceptLimitedFetchData, "limited accepted")
              }
            >
              <DoneIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share species diversity data anonymously" arrow>
            <IconButton
              onClick={() => handleRequest(id, rejectFetchData, "declined")}
            >
              <ClearIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel request" arrow>
            <IconButton
              onClick={() => handleRequest(id, revokeFetchData, "canceled")}
            >
              <UndoIcon fontSize="medium" color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  return null;
}

export default ShareRequestActions;
