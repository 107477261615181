import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { theme } from "./styles/useTheme";
import { useAuth } from "./context/AuthContext";
import LoginPage from "./pages/loginPage/LoginPage";
import UserListPage from "./pages/userListPage/UserListPage";
import PrivateRoute from "./privateRoute/PrivateRoute";
import FarmListPage from "./pages/farmListPage/FarmListPage";
import FarmsReportsPage from "./pages/reports/FarmsReportsPage";
import UserProfilePage from "./pages/userProfilePage/UserProfilePage";
import NotFoundPage from "./pages/pageNotFound/NotFoundPage";
import UserEditPage from "./pages/userEditPage/UserEditPage";
import FarmProfilePage from "./pages/farmProfilePage/FarmProfilePage";
import ListRecordingSitePage from "./pages/listRecordingSitePage/ListRecordingSitePage";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import UpdatePasswordPage from "./pages/password/UpdatePasswordPage";
import UserCreatePage from "./pages/userCreatePage/UserCreatePage";
import PublicRoute from "./publicRoute/PublicRoute";
import VerificationPage from "./pages/verificationPage/VerificationPage";
import ProfilePage from "./pages/profilePage/ProfilePage";
import ResetPasswordPage from "./pages/password/ResetPasswordPage";
import { userRole } from "./constants/userRoleConstants";
import OrganizationListPage from "./pages/organizationListPage/OrganizationListPage";
import OrganizationCreatePage from "./pages/organizationCreatePage/OrganizationCreatePage";
import OrganizationEditPage from "./pages/organizationEditPage/OrganizationEditPage";
import OrganizationProfilePage from "./pages/organizationProfilePage/OrganizationProfilePage";
import BenchmarkGroupsListPage from "./pages/benchmarkGroupsListPage/BenchmarkgroupsListPage";
import BenchmarkGroupCreatePage from "./pages/benchmarkGroupCreatePage/BenchmarkGroupCreatePage";
import BenchmarkGroupProfilePage from "./pages/benchmarkGroupProfilePage/BenchmarkGroupProfilePage";
import BenchmarkGroupEditPage from "./pages/benchmarkGroupEditPage/BenchmarkgroupEditPage";
import ListShareRequestsTable from "./pages/listShareRequestPage/ListShareRequestsTable";
import ShareRequestsPage from "./pages/shareRequestsPage/ShareRequestsPage";
import ShareRequestMessagePage from "./pages/shareRequestMessagePage/ShareRequestMessagePage";
import SpeciesListPage from "./pages/speciesListPage/SpeciesListPage";
import SpeciesProfilePage from "./pages/speciesProfilePage/SpeciesProfilePage";
import SpeciesEditPage from "./pages/speciesEditPage/SpeciesEditPage";
import FarmsInBGListPage from "./pages/farmsInBGListPage/FarmsInBGListPage";
import DiversityReport from "./pages/reports/report2/DiversityReport";
import FarmCreate from "./pages/farmCreateAndEdit/FarmCreate";
import FarmEditBasicInfo from "./pages/farmCreateAndEdit/FarmEditBasicInfo";
import FarmEditAdditionalInfo from "./pages/farmCreateAndEdit/FarmEditAdditionalInfo";

function App() {
  const { user } = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <ToastContainer />
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<LoginPage />}>
                <Route path=":email" element={<LoginPage />} />
              </Route>
              <Route
                path="/user/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="/user/reset-password"
                element={<ResetPasswordPage />}
              />
              <Route path="/user/verify" element={<VerificationPage />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route
                path="/check-requests/:id"
                element={<ShareRequestMessagePage />}
              />
              {user?.role &&
                {
                  [userRole.admin]: (
                    <>
                      <Route path="/users" element={<UserListPage />} />
                      <Route path="/users/:id" element={<UserProfilePage />} />
                      <Route
                        path="/users/edit/:id"
                        element={<UserEditPage />}
                      />
                      <Route
                        path="/users/create"
                        element={<UserCreatePage />}
                      />
                      <Route path="/farms" element={<FarmListPage />} />
                      <Route path="/farms/:id" element={<FarmProfilePage />} />
                      <Route path="/farms/create" element={<FarmCreate />} />

                      <Route
                        path="/farms/edit/basic-info/:id"
                        element={<FarmEditBasicInfo />}
                      />
                      <Route
                        path="/farms/edit/additional-info/:id"
                        element={<FarmEditAdditionalInfo />}
                      />
                      <Route
                        path="/farms/:id/recording-sites"
                        element={<ListRecordingSitePage />}
                      />
                      <Route
                        path="/organization"
                        element={<OrganizationListPage />}
                      />
                      <Route
                        path="organization/create"
                        element={<OrganizationCreatePage />}
                      />
                      <Route
                        path="organization/edit/:id"
                        element={<OrganizationEditPage />}
                      />
                      <Route
                        path="/organization/:id"
                        element={<OrganizationProfilePage />}
                      />
                      <Route
                        path="/benchmark-groups"
                        element={<BenchmarkGroupsListPage />}
                      />
                      <Route
                        path="benchmark-groups/create"
                        element={<BenchmarkGroupCreatePage />}
                      />
                      <Route
                        path="/benchmark-groups/:id"
                        element={<BenchmarkGroupProfilePage />}
                      />
                      <Route
                        path="benchmark-groups/edit/:id"
                        element={<BenchmarkGroupEditPage />}
                      />
                      <Route
                        path="/share-requests"
                        element={<ShareRequestsPage />}
                      />
                      <Route
                        path="/farms/:id/share-requests"
                        element={<ListShareRequestsTable path="farms" />}
                      />
                      <Route
                        path="/species/:id"
                        element={<SpeciesProfilePage />}
                      />
                      <Route
                        path="/species/edit/:id"
                        element={<SpeciesEditPage />}
                      />
                      <Route
                        path="/benchmark-groups/:id/farms"
                        element={<FarmsInBGListPage />}
                      />
                      <Route
                        path="/reports/benchmark-report"
                        element={<DiversityReport />}
                      />
                    </>
                  ),
                  [userRole.organization_admin]: (
                    <>
                      <Route
                        path="/organization"
                        element={<OrganizationProfilePage />}
                      />
                      <Route
                        path="/benchmark-groups"
                        element={<BenchmarkGroupsListPage />}
                      />
                      <Route
                        path="/benchmark-groups/:id"
                        element={<BenchmarkGroupProfilePage />}
                      />

                      <Route
                        path="/share-requests"
                        element={<ShareRequestsPage />}
                      />
                      <Route path="/farms/:id" element={<FarmProfilePage />} />
                      <Route
                        path="/benchmark-groups/:id/farms"
                        element={<FarmsInBGListPage />}
                      />
                    </>
                  ),
                  [userRole.farm_admin]: (
                    <>
                      <Route path="/farms" element={<FarmProfilePage />} />
                      <Route path="/farms/:id" element={<FarmProfilePage />} />
                      <Route
                        path="/farms/edit/basic-info/:id"
                        element={<FarmEditBasicInfo />}
                      />
                      <Route
                        path="/farms/edit/additional-info/:id"
                        element={<FarmEditAdditionalInfo />}
                      />
                      <Route
                        path="/farms/:id/recording-sites"
                        element={<ListRecordingSitePage />}
                      />
                      <Route
                        path="/share-requests"
                        element={<ListShareRequestsTable path="farms" />}
                      />

                      <Route
                        path="/farms/:id/share-requests"
                        element={<ListShareRequestsTable path="farms" />}
                      />
                      <Route
                        path="/reports/benchmark-report"
                        element={<DiversityReport />}
                      />
                    </>
                  ),
                }[user.role]}
              <Route
                path="/reports/farm-report"
                element={<FarmsReportsPage />}
              />
              <Route path="/species" element={<SpeciesListPage />} />
              <Route path="*" element={<NotFoundPage />} />
              <Route
                path="/user/change-password"
                element={<UpdatePasswordPage />}
              />
              <Route path="/user" element={<ProfilePage />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
