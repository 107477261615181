import { Box, Button, Modal, Typography } from "@mui/material";
import { styleModal } from "../../styles/customStyles";
import { DisabledModalProps } from "../../types/PropsTypes";

function DisabledModalMessage({
  open,
  handleActivate,
  handleDeactivate,
  handleClose,
  checked,
  name,
}: DisabledModalProps) {
  const activate = () => handleActivate("check");
  const deactivate = () => handleDeactivate("check");

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Box p={1}>
          <>
            <Typography variant="h6" textAlign="center" pb={3}>
              Are you sure you want to {!checked ? "activate" : "deactivate"}{" "}
              this {name}?
            </Typography>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="contained"
                onClick={!checked ? activate : deactivate}
              >
                Yes
              </Button>
              <Button variant="contained" onClick={handleClose}>
                No
              </Button>
            </Box>
          </>
        </Box>
      </Box>
    </Modal>
  );
}

export default DisabledModalMessage;
