import {
  Livestock as LivestockType,
  WaterKeys,
  Waters,
} from "../../types/DataTypes";

export const sustainabilitySystemsList = [
  "Countryside Stewardship Scheme for biodiversity (UK)",
  "Certified Organic (or in conversion)",
  "Organic practices (but uncertified)",
  "LEAF",
  "Tesco Sustainable Farming Group sustainability scorecard",
  "Red Tractor",
  "Biodiversity Action Plan",
  "A Biodiversity Credit system (e.g. Biodiversity Net Gain)",
  "Not Applicable",
];

export const retailSuppliersList = [
  "Tesco",
  "Sainsbury's",
  "Waitrose",
  "Co-operative",
  "M&S",
  "Asda",
  "Lidl",
  "Aldi",
  "Morrisons",
  "Not Applicable",
];

export const dairySuppliersList = [
  "Arla",
  "Muller",
  "Omsco",
  "FirstMilk",
  "Ornua",
  "Dairy Crest",
  "Meadow Foods",
  "Dale Farm",
  "Yeo Valley",
  "Glanbia",
  "County Milk",
  "Not Applicable",
];

export const meatSuppliersList = [
  "Hilton Food Group",
  "ABP",
  "Foyles",
  "SCFF",
  "2 Sisters",
  "Dunbia",
  "Kepak",
  "Dawn Meats",
  "Not Applicable",
];

export const beverageSuppliersList = [
  "Diageo",
  "Pepsico",
  "Anheuser-Busch",
  "Coca-Cola",
  "Heineken",
  "Pernod Ricard",
  "Asahi",
  "Britvic",
  "Not Applicable",
];
export const livestockList = ["Dairy cattle", "Beef cattle", "Sheep"];
export const watersList = ["Stream / River", "Pond / Lake", "Seasonal wetland"];

export const getArrayFromString = (systems: string) => {
  if (!systems) return [];

  return systems
    .split(",")
    .map((system: string) => system.trim())
    .filter((system: string) => !!system);
};

export const concatUniqueValues = (arr1: string[], arr2: string[]) => {
  return arr1.concat(arr2.filter((system: string) => arr1.indexOf(system) < 0));
};

export const onSubmitLogic = (
  values: any,
  additionalData: any,
  mainInterest: string
) => {
  const otherSustainabilitySystems = getArrayFromString(
    values.otherSustainabilitySystems
  );

  const otherRetailSuppliers = getArrayFromString(values.otherRetailSuppliers);

  const otherDairySuppliers = getArrayFromString(values.otherDairySuppliers);

  const otherMeatSuppliers = getArrayFromString(values.otherMeatSuppliers);

  let selectedSustainabilitySystems = [];
  let selectedRetailSuppliers = [];
  let selectedDairySuppliers = [];
  let selectedMeatSuppliers = [];
  let waters = {} as Waters;
  let livestock = {};

  if (additionalData.current) {
    selectedSustainabilitySystems =
      additionalData.current.getSelectedSustainabilitySystems();
    selectedRetailSuppliers =
      additionalData.current.getSelectedRetailSuppliers();
    selectedDairySuppliers = additionalData.current.getSelectedDairySuppliers();
    selectedMeatSuppliers = additionalData.current.getSelectedMeatSuppliers();

    waters = additionalData.current.getWaters();
    livestock = additionalData.current.getLivestockCattle();
  }

  const sustainabilitySystems = concatUniqueValues(
    selectedSustainabilitySystems,
    otherSustainabilitySystems
  );

  const retailSuppliers = concatUniqueValues(
    selectedRetailSuppliers,
    otherRetailSuppliers
  );

  const dairySuppliers = concatUniqueValues(
    selectedDairySuppliers,
    otherDairySuppliers
  );

  const meatSuppliers = concatUniqueValues(
    selectedMeatSuppliers,
    otherMeatSuppliers
  );

  Object.keys(waters).forEach((key) => {
    const k = key as WaterKeys;

    if (waters[k]) {
      if (waters[k] === null) delete waters[k];
      if (waters[k] === "yes") waters[k] = true;
      if (waters[k] === "no") waters[k] = false;
    }
  });

  const landUsage = values.additional_data.land_usage;

  Object.keys(landUsage).forEach((key) => {
    if (landUsage[key] === "") delete landUsage[key];
  });

  const valuesToSend = {
    ...values,
    county: values.county.value,
    main_interest: mainInterest,
    additional_data: {
      ...values.additional_data,
      sustainability_systems: sustainabilitySystems,
      retail_suppliers: retailSuppliers,
      dairy_suppliers: dairySuppliers,
      meat_suppliers: meatSuppliers,
      natural_water: waters,
      land_usage: landUsage,
      livestock_stocking_density: livestock,
    },
  };
};
