import { ChangeEvent } from "react";

import { TableFooter, TablePagination, TableRow } from "@mui/material";
import { useNavigate } from "react-router";
import { PaginationComponentProps } from "../../types/PropsTypes";

function PaginationComponent<T = unknown>({
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  path,
}: PaginationComponentProps<T>) {
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    navigate({
      pathname: path,
      search: `?page=${newPage}`,
    });
    sessionStorage.setItem("page", `${newPage + 1}`);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="td"
          count={count ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  );
}
export default PaginationComponent;
