/* eslint-disable no-param-reassign */
import { useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";

import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";

import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { CircularProgress, Typography } from "@mui/material";

import { validationSchemaUpdatePassword } from "../../validation/validationSchemaUpdatePassword";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import BackComponent from "../../components/back/BackComponent";
import { useAuth } from "../../context/AuthContext";
import { notify } from "../../utils/notify";
import { ErrorTypes } from "../../types/DataTypes";
import InputVisibilityPassword from "../../components/input/InputVisibilityPassword";

function UpdatePasswordPage() {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestHeader: any = useMemo(() => {
    return { Authorization: `Bearer ${token}` };
  }, [token]);
  const { loading, error, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/auth/change-password`,
    method: "PUT",
    headers: requestHeader,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPass: "",
      newPass: "",
      confirmPass: undefined,
    },
    validationSchema: validationSchemaUpdatePassword,
    onSubmit: (values) => {
      delete values.confirmPass;
      fetchData(values)
        .then(() => {
          notify("Password successfully updated!");
          navigate("/user");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });
  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
          <Card elevation={3}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ textAlign: "center", p: 3 }}>
                <CardHeader title="Update password" />
                <Divider />
                <CardContent>
                  <Box pt={2}>
                    <InputVisibilityPassword
                      id="oldPass"
                      placeholder="Enter current password..."
                      label="Current password"
                      value={formik.values.oldPass}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showPassword={showOldPassword}
                      setShowPassword={setShowOldPassword}
                    />
                    {formik.touched.oldPass && formik.errors.oldPass && (
                      <FormHelperText error>
                        {formik.errors.oldPass}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box pt={3}>
                    <InputVisibilityPassword
                      id="newPass"
                      placeholder="Enter new password..."
                      label="New password"
                      value={formik.values.newPass}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showPassword={showNewPassword}
                      setShowPassword={setShowNewPassword}
                    />
                    {formik.touched.newPass && formik.errors.newPass && (
                      <FormHelperText error>
                        {formik.errors.newPass}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box pt={3} pb={2}>
                    <InputVisibilityPassword
                      id="confirmPass"
                      placeholder="Enter confirmation password..."
                      label="Confirmation password"
                      value={formik.values.confirmPass}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                    />
                    {formik.touched.confirmPass &&
                      formik.errors.confirmPass && (
                        <FormHelperText error>
                          {formik.errors.confirmPass}
                        </FormHelperText>
                      )}
                  </Box>
                  <Box pt={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  </Box>
                </CardContent>
              </Box>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
export default UpdatePasswordPage;
