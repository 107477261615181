import { TableCell } from "@mui/material";
import { TableCellWithDivProps } from "../../types/PropsTypes";

function TableCellWithDiv({
  onClick,
  content,
  sx,
  width = "180px",
}: TableCellWithDivProps) {
  return (
    <TableCell sx={sx} onClick={onClick} align="center">
      <div
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "hidden",
          width,
        }}
      >
        {content}
      </div>
    </TableCell>
  );
}
export default TableCellWithDiv;
