import React from "react";
import { Line } from "react-chartjs-2";
import { Box, Typography } from "@mui/material";
import ReportCard from "../../../components/cards/ReportCard";
import { theme } from "../../../styles/useTheme";
import {
  ReportFarmDataType,
  TrendDataType,
  TrendType,
} from "../../../types/DataTypes";

export default function TrendChart({
  trend,
  farm,
}: {
  trend: TrendType;
  farm: ReportFarmDataType;
}) {
  const { benchmark_group: benchmarkTrend, farm: farmTrend } = trend;
  const {
    name: farmName,
    benchmark_group: { name: benchmarkName },
  } = farm;
  const objectExists = (trendData: TrendDataType) =>
    Boolean(Object.keys(trendData).length);
  const farmTrendExists = objectExists(farmTrend);
  const benchmarkTrendExists = objectExists(benchmarkTrend);

  const generateLabels = () => {
    // make array with unique values from both trends, all years together then remove duplicates
    const trendKeys = new Set([
      ...Object.keys(farmTrend),
      ...Object.keys(benchmarkTrend),
    ]);
    const labels = Array.from(trendKeys).sort();
    // add first and last label to be for 1 smaller/larger than the smallest/largest label
    const firstLabel = Number(labels[0]) - 1;
    const lastLabel = Number(labels[labels.length - 1]) + 1;
    labels.unshift(firstLabel.toString());
    labels.push(lastLabel.toString());
    return labels;
  };
  const generateDataSet = (data: TrendDataType) => {
    const labels = generateLabels();
    if (!objectExists(data)) return [];
    const years = Object.keys(data);
    // check if first year of labels is the same year that's first in trend keys,
    // and if that is true just return existing array with nulls around for added labels
    if (labels[1] === years[0]) return [null, ...Object.values(data), null];
    // check in which place does first key of trend object appears in labels(which are made from trend keys),
    // and fill that many spaces with nulls as to push the line start that many places to the right
    const firstAppearance = labels.indexOf(years[0]);
    const filler = new Array(firstAppearance).fill(null);
    return [...filler, ...Object.values(data), null];
  };
  // generating dataset arrau only if farm trend or benchmark trend exist
  const checkDatasets = () => {
    const datasets = [];
    const farmDataSet = {
      label: farmName,
      data: generateDataSet(farmTrend),
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    };
    const benchmarkDataset = {
      label: benchmarkName,
      data: generateDataSet(benchmarkTrend),
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
      spanGaps: true,
    };
    if (farmTrendExists) datasets.push(farmDataSet);
    if (benchmarkTrendExists) datasets.push(benchmarkDataset);
    return datasets;
  };
  // options and data for chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      yAxis: {
        min: 0,
      },
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: generateLabels(),
    datasets: checkDatasets(),
  };
  const content = () => {
    if (!farmTrendExists && !benchmarkTrendExists) {
      return (
        <Typography variant="h6" textAlign="center">
          There is not enough data to calculate trends
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="subtitle1" textAlign="center">
          {!farmTrendExists &&
            "There is not enough data to calculate trend for your farm"}
          {!benchmarkTrendExists &&
            "There is not enough data to calculate trend for benchmark group"}
        </Typography>
        <Box>
          <Line options={options} data={data} />
        </Box>
      </>
    );
  };

  return <ReportCard title="Trend">{content()}</ReportCard>;
}
