import React from "react";
import { Navigate, Outlet } from "react-router";
import NavBarComponent from "../components/navbar/NavbarComponent";
import { useAuth } from "../context/AuthContext";

function PrivateRoute() {
  const { user, isAuthFinish } = useAuth();
  if (isAuthFinish) {
    return user ? (
      <NavBarComponent>
        <Outlet />
      </NavBarComponent>
    ) : (
      <Navigate to="/" />
    );
  }
  return null;
}
export default PrivateRoute;
