/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { FormHelperText } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box/Box";
import { useField, FieldHookConfig } from "formik";

function InputField({
  label,
  ...props
}: TextFieldProps & FieldHookConfig<string>) {
  const [field, meta] = useField(props);
  return (
    <Box mb={4} mt={4}>
      <TextField fullWidth size="small" label={label} {...field} {...props} />
      {meta.touched && meta.error ? (
        <FormHelperText error>{meta.error}</FormHelperText>
      ) : null}
    </Box>
  );
}

export default InputField;
