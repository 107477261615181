import React, { ChangeEvent, FocusEvent } from "react";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useField } from "formik";
import { FarmData } from "../../types/DataTypes";

export default function InputFieldFormik({
  name,
  label,
}: {
  name: keyof FarmData;
  label: string;
}) {
  const [field, meta] = useField(name);
  return (
    <Box>
      <TextField label={label} size="small" fullWidth {...field} />
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Box>
  );
}
