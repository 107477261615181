import axios from "axios";
import { useState, useCallback } from "react";
import { ApiResponseTypeMutate, ErrorTypes } from "../types/DataTypes";
import { Props } from "../types/PropsTypes";

const defaultHeader = { "Content-Type": "application/json" };

function useMutate<T>({ url, method, headers }: Props): ApiResponseTypeMutate {
  const [error, setError] = useState<ErrorTypes | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (data: T) => {
      setLoading(true);
      const response = axios(url, {
        method,
        headers: headers ? { ...defaultHeader, ...headers } : defaultHeader,
        data: JSON.stringify(data || {}),
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          setError(err.response.data);
          throw Error(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
      return response;
    },
    [method, url, headers]
  );

  return { loading, error, fetchData };
}

export default useMutate;
