import React, { useState, useMemo } from "react";
import { Formik } from "formik";

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

import InputField from "../forms/InputFieldForm";
// eslint-disable-next-line max-len
import { validationSchemaAddRecordingSite } from "../../validation/validationSchemaAddRecordingSite";
import ButtonComponent from "../button/ButtonComponent";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useMutate from "../../service/useMutate";
import { useAuth } from "../../context/AuthContext";
import { RecordingSiteModalEditProps } from "../../types/PropsTypes";
import { ErrorTypes } from "../../types/DataTypes";
import { styleModal } from "../../styles/customStyles";
import { notify } from "../../utils/notify";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import CheckboxForm from "../forms/CheckboxForm";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";

function EditRecordingSiteModal({
  responseData,
  refreshRecordingSiteList,
}: RecordingSiteModalEditProps) {
  const [open, setOpen] = useState<boolean>(false); // controls open modal
  const { token } = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { loading, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/recording-sites/${responseData?.id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const initialValues = {
    location: {
      latitude: responseData?.location.latitude || 0,
      longitude: responseData?.location.longitude || 0,
    },
    memory_card_id: responseData?.memory_card_id || "",
    name: responseData?.name || "",
    description: responseData?.description || "",
    is_active: responseData?.is_active || true,
  };

  const onSubmit = (values: typeof initialValues) => {
    fetchData(values)
      .then(() => {
        refreshRecordingSiteList();
        notify("Recording site successfully updated!");
        setOpen(false);
      })
      .catch((error: ErrorTypes) => {
        notify("Request failed!", error);
        setOpen(false);
      });
  };
  return (
    <>
      <Grid item textAlign="right">
        <IconButton onClick={handleOpen}>
          <EditIcon fontSize="medium" color="action" />
        </IconButton>
      </Grid>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <Typography
            textAlign="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit recording site
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchemaAddRecordingSite}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <InputField name="name" label="Name" />
                <InputField name="location.latitude" label="Latitude" />
                <InputField name="location.longitude" label="Longitude" />
                <InputField name="memory_card_id" label="Latest device ID" />
                <InputField
                  name="description"
                  label="Description"
                  multiline
                  rows={3}
                />
                <Box textAlign="left">
                  <FormControlLabel
                    control={
                      <CheckboxForm
                        id="check"
                        name="is_active"
                        type="checkbox"
                      />
                    }
                    label="Recording site is active"
                  />
                </Box>
                <ConfirmationModal
                  onConfirm={() => props.handleSubmit()}
                  renderElement={({ openModal }) => (
                    <Button
                      onClick={openModal}
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  )}
                />
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default EditRecordingSiteModal;
