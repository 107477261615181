import React from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";

import Container from "@mui/material/Container";
import WebSiteLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import { SpeciesDataResponseType } from "../../types/DataTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";

function SpeciesProfilePage() {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();

  const { responseData, loading, error } = useFetch<SpeciesDataResponseType>({
    url: `${REACT_APP_API_URL}/species/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {responseData && (
          <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <Box textAlign="center">
                <Box display="flex">
                  <Box flexGrow={2} pl={5}>
                    <CardHeader title={responseData.latin} />
                  </Box>
                  <Box p={2}>
                    <Link to={`/species/edit/${id}`}>
                      <EditIcon fontSize="medium" color="action" />
                    </Link>
                  </Box>
                </Box>
                <Divider />
                <CardContent>
                  <Box textAlign="left" p={3}>
                    <Typography gutterBottom variant="h6">
                      Latin name:{" "}
                      <Typography gutterBottom variant="body1">
                        {responseData.latin}
                      </Typography>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      English name:{" "}
                      <Typography gutterBottom variant="body1">
                        {responseData.english}
                      </Typography>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      International name:{" "}
                      <Typography gutterBottom variant="body1">
                        {responseData.international}
                      </Typography>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      Habitat:{" "}
                      <Typography gutterBottom variant="body1">
                        {responseData.habitat}
                      </Typography>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      Trophic:{" "}
                      <Typography gutterBottom variant="body1">
                        {responseData.tropic}
                      </Typography>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      Song URL:{" "}
                      <Typography
                        gutterBottom
                        variant="body1"
                        sx={{ cursor: "pointer" }}
                      >
                        <WebSiteLink
                          onClick={() =>
                            handleNavigateWebsite(responseData.song_url)
                          }
                        >
                          {responseData.song_url}
                        </WebSiteLink>
                      </Typography>
                    </Typography>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default SpeciesProfilePage;
