import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { notify } from "../../utils/notify";
import { FarmData } from "../../types/DataTypes";
import FarmBasicInfoForm from "./FarmBasicInfoForm";
import useFetch from "../../service/useFetch";
import BackComponent from "../../components/back/BackComponent";

export default function FarmCreate() {
  const { token } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const { responseData, loading } = useFetch<FarmData>({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const initialValues: FarmData = {
    name: responseData?.name || "",
    CPH_number: responseData?.CPH_number || "",
    SBI_number: responseData?.SBI_number || "",
    address: responseData?.address || "",
    county: responseData?.county || "",
    post_code: responseData?.post_code || "",
    is_active: responseData?.is_active || true,
    main_interest: responseData?.main_interest || "curious-about-birds-on-farm",
  };
  const onSubmit = (values: FarmData) => {
    fetchData(values)
      .then(() => {
        notify("Farm successfully edited!");
        navigate(`/farms/${id}`);
      })
      .catch((error) => {
        notify("Something went wrong", error);
      });
  };

  return (
    <>
      <BackComponent />
      <Card
        sx={{
          maxWidth: { md: "50vw" },
          ml: { md: "auto" },
          mr: { md: "auto" },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h5">Edit farm</Typography>
            <Box>
              <FarmBasicInfoForm
                initialValues={initialValues}
                onSubmit={onSubmit}
              />
            </Box>
          </CardContent>
        )}
      </Card>
    </>
  );
}
