import * as Yup from "yup";

export const validationSchemaEditUser = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email address is required"),
  name: Yup.string()
    .min(3, "Name must contain at least 3 characters")
    .max(25, "Name must contain max 25 characters")
    .required("Name is required"),
  role: Yup.string().required("Role is required"),
  is_active: Yup.bool().optional(),
});
