import React, { ReactElement } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

interface PopoverProps {
  renderElement?: ({
    handlePopoverOpen,
    handlePopoverClose,
    open,
  }: {
    handlePopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handlePopoverClose: () => void;
    open: boolean;
  }) => ReactElement;
  children: string;
}

export default function PopoverOnHover({
  renderElement,
  children,
}: PopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {renderElement?.({ handlePopoverOpen, handlePopoverClose, open })}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }} variant="body2">
          {children}
        </Typography>
      </Popover>
    </div>
  );
}
