import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import BarChartCard from "./BarChartCard";
import ConfirmationModal from "../../../components/confirmationModal/ConfirmationModal";
import { useAuth } from "../../../context/AuthContext";
import useFetch from "../../../service/useFetch";
import { DataFarmsResponseType } from "../../../types/DataTypes";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import areAllPropertiesEmpty from "../../../utils/isObjectEmpty";

export default function DiversityReport() {
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const { responseData } = useFetch<DataFarmsResponseType>({
    url: `${REACT_APP_API_URL}/farms/my-farm`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(user?.role === "FARM_ADMIN"),
  });

  const initOpen =
    user?.role === "FARM_ADMIN" &&
    areAllPropertiesEmpty(responseData?.additional_data) &&
    responseData;

  const handleModalConfirm = () =>
    navigate(`/farms/edit/additional-info/${responseData?.id}`);
  const handleModalCancel = () => navigate(-1);

  return (
    <>
      {initOpen && (
        <ConfirmationModal
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          text="Please enter additional data to see benchmark report"
          btnConfirmText="Edit farm"
          btnCancelText="Back"
          initiallyOpen
        />
      )}
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          {/* <div>Pyramid report</div> */}
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <div>Agri-environment report</div> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <BarChartCard />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <div>Land Use report</div> */}
        </Grid>
      </Grid>
    </>
  );
}
