import React from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { InputVisibilityPasswordProps } from "../../types/PropsTypes";

function InputVisibilityPassword({
  id,
  placeholder,
  label,
  value,
  onChange,
  showPassword,
  setShowPassword,
  onBlur,
}: InputVisibilityPasswordProps) {
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      margin="dense"
      fullWidth
      size="small"
      label={label}
      type={showPassword ? "text" : "password"}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
export default InputVisibilityPassword;
