import React from "react";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <footer className="footer">
      All information on <a href="https://app.chirrup.ai/">app.chirrup.ai</a> is
      provided in good faith, however we make no representation or warranty of
      any kind, express or implied, regarding the accuracy, adequacy, validity,
      reliability, availability, or completeness of any information on
      <a href="https://app.chirrup.ai/"> app.chirrup.ai</a>
    </footer>
  );
}

export default Disclaimer;
