import React from "react";

import Container from "@mui/material/Container/Container";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import BackComponent from "../../components/back/BackComponent";

function NotFoundPage() {
  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" mt={20}>
        <BackComponent />
        <Typography gutterBottom variant="h1">
          Oops!{" "}
        </Typography>
        <Typography gutterBottom variant="h4" color="error">
          404 Page not found
        </Typography>
      </Box>
    </Container>
  );
}
export default NotFoundPage;
