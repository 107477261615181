import * as Yup from "yup";

export const validationSchemaCreateFarm = Yup.object({
  name: Yup.string()
    .min(3, "Name must contain at least 3 characters")
    .max(25, "Name must contain max 25 characters")
    .required("Name is required"),
  CPH_number: Yup.string().optional(),
  SBI_number: Yup.string().optional(),
  address: Yup.string().required("Address is required"),
  county: Yup.string().required("County is required"),
  post_code: Yup.string()
    .required("Post code is required")
    .min(5, "Post code must contain at least 5 characters")
    .max(8, "Post code must contain max 8 characters"),
  is_active: Yup.boolean().optional(),
  main_interest: Yup.string().optional(),
});
