import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { User } from "../types/DataTypes";
import { ContextProps } from "../types/PropsTypes";
import { parseJwt } from "../utils/parseJwt";

const initialStateContext = {
  user: null,
  token: null,
  setToken: () => null,
  isAuthFinish: false,
  logout: () => null,
  loading: false,
};

export const AuthContext = createContext<ContextProps>(initialStateContext);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, _setToken] = useState<string | null>("");
  const [isAuthFinish, setIsAuthFinish] = useState<boolean>(false);
  const tokenFromStorage = localStorage.getItem("token");
  useEffect(() => {
    if (tokenFromStorage) {
      setLoading(true);
      const decodedToken = tokenFromStorage && parseJwt(tokenFromStorage);
      setUser(decodedToken);
      _setToken(tokenFromStorage);
      setIsAuthFinish(true);
      setLoading(false);
    } else {
      setIsAuthFinish(true);
      setLoading(false);
    }
  }, [tokenFromStorage]);

  function setToken(token: string) {
    localStorage.setItem("token", token);
    _setToken(token);
  }

  function logout() {
    setUser(null);
    localStorage.removeItem("token");
    _setToken(null);
  }

  const value = useMemo(
    () => ({
      user,
      token,
      isAuthFinish,
      loading,
      setToken,
      logout,
    }),
    [user, token, isAuthFinish, loading]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
