import React, { useState } from "react";

import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper/Paper";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import Table from "@mui/material/Table/Table";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { Box, Container, Divider, Link, Typography } from "@mui/material";
import { PathParams } from "../../types/PropsTypes";
import { ResponseRecordingSite } from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import AddRecordingSiteModal from "../../components/modal/AddRecordingSiteModal";
import TitleComponent from "../../components/title/TitleComponent";
import AudioFileUpload from "../../components/modal/AudioFileUpload";
import EditRecordingSiteModal from "../../components/modal/EditRecordingSiteModal";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { userRole } from "../../constants/userRoleConstants";
import CalendarModal from "../../components/modal/CalendarModal";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";

function ListRecordingSitesCard({ farmId }: { farmId?: string }) {
  const { id } = useParams<PathParams>();
  const { token, user } = useAuth();
  const [refresh, setRefresh] = useState(Math.random());
  const requestId = id || farmId;
  const { responseData, loading, error } = useFetch<ResponseRecordingSite[]>({
    url: `${REACT_APP_API_URL}/farms/${requestId}/recording-sites`,
    method: "GET",
    headers: requestHeaderToken(token),
    refresh,
  });

  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };

  const handleNavigateGoogleMaps = (latitude: number, longitude: number) => {
    window.open(`http://maps.google.com/?q=${latitude},${longitude}`);
  };
  return (
    <div key={refresh}>
      {error ? (
        <Box sx={{ border: 1, borderColor: "error.main" }}>
          <Typography variant="h5" p={3} textAlign="center" color="error">
            An error occurred while retrieving recording sites
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "570px",
            }}
          >
            <Box p={2} sx={{ textAlign: "center" }}>
              <TitleComponent variant="h6" title="Recording sites" />
            </Box>
            <Divider />
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <Table
                  aria-label="table"
                  sx={{
                    height: "max-content",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Location longitude</TableCell>
                      <TableCell align="center">Location latitude</TableCell>
                      {user?.role &&
                        {
                          [userRole.admin]: (
                            <>
                              <TableCell align="center">Upload</TableCell>
                              <TableCell align="center">Edit</TableCell>
                              <TableCell align="center">Overview</TableCell>
                            </>
                          ),
                        }[user.role]}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && !responseData && (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {responseData &&
                      responseData.map((data: ResponseRecordingSite) => {
                        const {
                          location: { latitude, longitude },
                        } = data;
                        return (
                          <TableRow key={data.id}>
                            <TableCellWithDiv
                              width={
                                user?.role &&
                                {
                                  [userRole.admin]: "55px",
                                  [userRole.farm_admin]: "100%",
                                }[user.role]
                              }
                              sx={{ cursor: "pointer" }}
                              content={
                                <Link
                                  href={`http://maps.google.com/?q=${latitude},${longitude}`}
                                  target="_blank"
                                >
                                  {data.name}
                                </Link>
                              }
                            />
                            <TableCellWithDiv
                              width={
                                user?.role &&
                                {
                                  [userRole.admin]: "61px",
                                  [userRole.farm_admin]: "100%",
                                }[user.role]
                              }
                              content={data.location.longitude}
                            />
                            <TableCellWithDiv
                              width={
                                user?.role &&
                                {
                                  [userRole.admin]: "61px",
                                  [userRole.farm_admin]: "100%",
                                }[user.role]
                              }
                              content={data.location.latitude}
                            />
                            {user?.role &&
                              {
                                [userRole.admin]: (
                                  <>
                                    <TableCell align="center">
                                      <AudioFileUpload
                                        token={token}
                                        recordingSiteId={data.id}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <EditRecordingSiteModal
                                        refreshRecordingSiteList={
                                          refreshRecordingSiteList
                                        }
                                        responseData={data}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <CalendarModal
                                        id={requestId}
                                        responseData={data}
                                      />
                                    </TableCell>
                                  </>
                                ),
                              }[user.role]}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </TableContainer>
          {user?.role &&
            {
              [userRole.admin]: (
                <AddRecordingSiteModal
                  refreshRecordingSiteList={refreshRecordingSiteList}
                />
              ),
            }[user.role]}
        </>
      )}
    </div>
  );
}
export default ListRecordingSitesCard;
