import React, { useState } from "react";
import DropdownSelect from "../../../components/dropdownSelect/DropdownSelect";
import {
  BenchmarkFilterProps,
  BenchmarkGroupsSelectionResponse,
  BenchmarkgroupSelectionObject,
} from "../../../types/PropsTypes";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { useAuth } from "../../../context/AuthContext";
import useFetch from "../../../service/useFetch";

export default function BenchmarkFilter({
  value,
  onChange,
}: BenchmarkFilterProps) {
  const { token } = useAuth();

  const { responseData: benchmarkGroups } =
    useFetch<BenchmarkGroupsSelectionResponse>({
      url: `${REACT_APP_API_URL}/benchmark-groups/role-filtered`,
      method: "GET",
      headers: requestHeaderToken(token),
    });
  const getText = (benchmarkGroup: BenchmarkgroupSelectionObject) =>
    benchmarkGroup.name;
  const getValue = (benchmarkGroup: BenchmarkgroupSelectionObject) =>
    benchmarkGroup.id;

  return (
    <DropdownSelect
      options={benchmarkGroups}
      label="Benchmark group"
      getText={getText}
      getValue={getValue}
      value={value}
      onChange={onChange}
    />
  );
}
