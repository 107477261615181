import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BackComponent() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box alignItems="left" mb={2}>
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
        Back
      </IconButton>
    </Box>
  );
}

export default BackComponent;
