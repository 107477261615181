import React, { useState, MouseEvent } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReportCard from "../../../components/cards/ReportCard";
import { ChirupRatingResponseType } from "../../../types/DataTypes";
import bird from "../../../assets/images/chirLogo.png";
import note from "../../../assets/images/chirNote.png";
import ChirrupRatingPopover from "./ChirrupRatingPopover";

export default function ChirrupRating({
  monitoring,
  top_quartile: topQuartile,
  improved,
}: ChirupRatingResponseType) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <ReportCard title="Chirrup rating">
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon />
      </IconButton>
      <ChirrupRatingPopover open={open} anchorElement={anchorEl} />
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        flexWrap="wrap"
        justifyContent="center"
        alignContent="center"
      >
        <Box display="flex">
          <img src={bird} alt="bird" height="60" width="75" />
          {monitoring && <img src={note} alt="bird" height="60" width="30" />}
          {topQuartile && <img src={note} alt="bird" height="60" width="30" />}
          {improved && <img src={note} alt="bird" height="60" width="30" />}
        </Box>
        <Box textAlign="center">
          <Typography
            variant="h6"
            sx={{
              opacity: `${monitoring ? "none" : "0.5"}`,
            }}
          >
            Monitoring
          </Typography>
          <Box>
            <Typography
              variant="h6"
              sx={{
                opacity: `${topQuartile ? "none" : "0.5"}`,
              }}
            >
              Top quartile
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                opacity: `${improved ? "none" : "0.5"}`,
              }}
            >
              Improved
            </Typography>
          </Box>
        </Box>
      </Box>
    </ReportCard>
  );
}
