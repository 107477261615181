/* eslint-disable react/no-unescaped-entities */
import { Modal, Box, Typography, Button } from "@mui/material";
import React from "react";
import { styleModal } from "../../styles/customStyles";

interface ProcedureNotesModalProps {
  open: boolean;
  children: any;
  handleClose: () => void;
}

function ProcedureNotesModal({
  open,
  children,
  handleClose,
}: ProcedureNotesModalProps) {
  return (
    <>
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: 800 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            A note about bioacoustic and AI-based species identification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span>
              Chirrup Ltd is working hard to constantly improve our service
              which, at the time of writing has been trained to recognise 140+
              UK and Ireland bird species. However, Chirrup's species listing
              for all farm recording sites is dependent upon two factors: 1) a
              set of variables that affect recording fidelity. These include but
              are not limited to choice of location, orientation of device,
              weather effects, anthropogenic noise, electronic interference,
              distance from vocalisation, and device malfunction 2) Chirrup's
              lab-trained identification algorithm, which is designed to improve
              in accuracy over time as it encounters new species. As such it is
              likely to be more accurate for common species vocalisations with
              which it has been extensively trained, and prone to error - either
              falsely identifying one species as another species, or failing to
              recognise a species at all - for rarer species vocalisations with
              which its training has been limited. Nonetheless, as Chirrup
              revises the algorithm (creating a new version) at the start of
              each new year, users can be reassured that the identifications
              across all the recording sites for the preceding year have all
              been equally subject to any systematic inaccuracies of that
              preceding year's version. As such, we consider the relative levels
              of species richness of any two sites to be correct in an ordinal
              sense, and thus comparable, within any one year. So if species
              lists are subsequently revised in this way, through reassessment
              by a more deeply trained version of the algorith a) a "top
              quartile" farm is likely to remain top quartile for the year in
              which this was reported and b) whether a farm has "improved"
              between years can be reported with more confidence.
            </span>
          </Typography>
          <Button sx={{ marginTop: "20px" }} onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default ProcedureNotesModal;
