/* eslint-disable no-param-reassign */
import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@mui/material";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useMutate from "../../service/useMutate";
import ButtonComponent from "../../components/button/ButtonComponent";
import TitleComponent from "../../components/title/TitleComponent";
import { notify } from "../../utils/notify";
import logo from "../../assets/images/chirLogo.png";
import { ErrorTypes } from "../../types/DataTypes";
import { validationSchemaResetPassword } from "../../validation/validationSchemaResetPassword";
import InputVisibilityPassword from "../../components/input/InputVisibilityPassword";

function ResetPasswordPage() {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const { fetchData, loading, error } = useMutate({
    url: `${REACT_APP_API_URL}/auth/reset-password`,
    method: "PUT",
  });
  const formik = useFormik({
    initialValues: {
      newPass: "",
      email,
      token,
      confirmPass: undefined,
    },
    validationSchema: validationSchemaResetPassword,
    onSubmit: (values) => {
      delete values.confirmPass;
      fetchData(values)
        .then(() => {
          navigate(`/${email}`);
          notify("Password successfully reset!");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {error && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h4" color="error">
              {error?.statusCode} {"  "}
              {error?.error}
            </Typography>
          </Box>
        )}
        {!matches && (
          <Grid sx={{ textAlign: "center" }} item xs={12} md={7}>
            <img src={logo} alt="logo" height={150} width={200} />

            <TitleComponent title="Chirrup.ai" variant="h3" align="center" />
          </Grid>
        )}
        {matches && (
          <Grid item xs={12} md={7}>
            <img src={logo} alt="logo" height={250} width={300} />

            <TitleComponent title="Chirrup.ai" variant="h2" align="center" />
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          <Card elevation={3} sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <CardHeader
                title={
                  <TitleComponent
                    title="Reset password"
                    variant="h4"
                    align="center"
                  />
                }
              />
              <CardContent>
                <Box pt={3}>
                  <InputVisibilityPassword
                    id="newPass"
                    placeholder="Enter new password..."
                    label="New password"
                    value={formik.values.newPass}
                    onChange={formik.handleChange}
                    showPassword={showNewPassword}
                    setShowPassword={setShowNewPassword}
                  />
                  {formik.errors.newPass && (
                    <FormHelperText error>
                      {formik.errors.newPass}
                    </FormHelperText>
                  )}
                </Box>
                <Box pt={3}>
                  <InputVisibilityPassword
                    id="confirmPass"
                    placeholder="Enter confirmation password..."
                    label="Confirmation password"
                    value={formik.values.confirmPass}
                    onChange={formik.handleChange}
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                  />
                  {formik.errors.confirmPass && (
                    <FormHelperText error>
                      {formik.errors.confirmPass}
                    </FormHelperText>
                  )}
                </Box>
                <CardActions sx={{ p: 0, pt: 7 }}>
                  <ButtonComponent
                    type="submit"
                    title="Submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  />
                </CardActions>
              </CardContent>
            </form>

            {loading && (
              <Box display="flex" justifyContent="center" mt={20}>
                <CircularProgress />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ResetPasswordPage;
