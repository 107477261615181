import React, { useState, useMemo } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useParams } from "react-router";
import Fab from "@mui/material/Fab/Fab";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import InputField from "../forms/InputFieldForm";
// eslint-disable-next-line max-len
import { validationSchemaAddRecordingSite } from "../../validation/validationSchemaAddRecordingSite";
import ButtonComponent from "../button/ButtonComponent";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { PathParams, RecordingSiteModalProps } from "../../types/PropsTypes";
import { ErrorTypes } from "../../types/DataTypes";
import { styleModal } from "../../styles/customStyles";
import { notify } from "../../utils/notify";
import { requestHeaderToken } from "../../utils/requestHeaderToken";

function AddRecordingSiteModal({
  refreshRecordingSiteList,
}: RecordingSiteModalProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { token } = useAuth();
  const { id: farmId } = useParams<PathParams>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { loading, fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${farmId}/recording-sites`,
    method: "POST",
    headers: requestHeaderToken(token),
  });

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid item textAlign="right" p={2}>
        <Fab color="primary" aria-label="add" onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <Typography
            textAlign="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add recording site
          </Typography>

          <Formik
            initialValues={{
              location: {
                latitude: "",
                longitude: "",
              },
              memory_card_id: "",
              is_active: true,
            }}
            onSubmit={(values) => {
              fetchData(values)
                .then(() => {
                  refreshRecordingSiteList();
                  notify("Recording site successfully created!");
                  setOpen(false);
                })
                .catch((error: ErrorTypes) => {
                  notify("Request failed!", error);
                  setOpen(false);
                });
            }}
            validationSchema={validationSchemaAddRecordingSite}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <InputField name="name" label="Name" />
                <InputField name="location.latitude" label="Latitude" />
                <InputField name="location.longitude" label="Longitude" />
                <InputField name="memory_card_id" label="Latest device ID" />
                <InputField
                  name="description"
                  label="Description"
                  multiline
                  rows={3}
                />
                <ButtonComponent
                  type="submit"
                  title="ADD"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                />
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default AddRecordingSiteModal;
