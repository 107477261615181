import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  TextField,
} from "@mui/material";
import { useState, ElementType } from "react";

function InputAutoComplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType<any> = "div"
>({
  options,
  multiple,
  onChange,
  getOptionLabel,
  disabled,
  label,
  defaultValue,
  value: propsValue,
}: Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  "renderInput"
> & { label: string }) {
  const [value, setValue] = useState<any>(propsValue);
  const [inputValue, setInputValue] = useState<string>("");

  const handleChangeCustom = (
    event: any,
    newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    reason: any,
    details: any
  ) => {
    setValue(newValue as T);
    if (onChange) onChange(event, newValue, reason, details);
  };
  return (
    <Autocomplete
      defaultValue={defaultValue}
      disabled={disabled}
      multiple={multiple}
      size="small"
      fullWidth
      disablePortal
      id="combo-box-demo"
      value={value}
      inputValue={inputValue}
      onChange={handleChangeCustom}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={getOptionLabel}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

export default InputAutoComplete;
