import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

import {
  AdditionalData,
  ShareData,
  WaterKeys,
  LandUsage,
} from "../../types/DataTypes";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";
import { useAuth } from "../../context/AuthContext";
import { userRole } from "../../constants/userRoleConstants";

export default function AdditionalFarmInformation({
  id,
  additionalData,
}: {
  id: string;
  additionalData: AdditionalData | undefined;
}) {
  const {
    farm_size: farmSize,
    sustainability_systems: sustainabilitySystems,
    retail_suppliers: retailSuppliers,
    dairy_suppliers: dairySuppliers,
    meat_suppliers: meatSuppliers,
    beverage_suppliers: beverageSuppliers,
    livestock_stocking_density: livestockDensity,
    natural_water: naturalWater,
    land_usage: landUsage,
  } = additionalData || {};
  const strToUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { user } = useAuth();
  return (
    <Box mt={3}>
      <Accordion elevation={3} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: 0 }}
        >
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="h6" flexGrow={2}>
              Additional information
            </Typography>
            {user?.role !== userRole.organization_admin && (
              <Box alignSelf="flex-end">
                <Link to={`/farms/edit/additional-info/${id}`}>
                  <EditIcon fontSize="small" color="action" />
                </Link>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {areAllPropertiesEmpty(additionalData) ? (
            <Typography variant="body1" align="center">
              No additional information for this farm
            </Typography>
          ) : (
            <Box p={2} display="flex" justifyContent="space-between">
              <Box>
                {farmSize ? (
                  <Typography gutterBottom variant="body1">
                    Farm size: {farmSize} ha
                  </Typography>
                ) : null}
                {sustainabilitySystems && sustainabilitySystems.length ? (
                  <Typography gutterBottom variant="body1">
                    Sustainability Systems: {sustainabilitySystems.join(", ")}
                  </Typography>
                ) : null}
                {retailSuppliers && retailSuppliers.length ? (
                  <Typography gutterBottom variant="body1">
                    Retail supplier to: {retailSuppliers.join(", ")}
                  </Typography>
                ) : null}
                {dairySuppliers && dairySuppliers.length ? (
                  <Typography gutterBottom variant="body1">
                    Dairy supplier to: {dairySuppliers.join(", ")}
                  </Typography>
                ) : null}
                {meatSuppliers && meatSuppliers.length ? (
                  <Typography gutterBottom variant="body1">
                    Meat supplier to: {meatSuppliers.join(", ")}
                  </Typography>
                ) : null}
                {beverageSuppliers && beverageSuppliers.length ? (
                  <Typography gutterBottom variant="body1">
                    Beverage supplier to: {beverageSuppliers.join(", ")}
                  </Typography>
                ) : null}
                {livestockDensity && Object.keys(livestockDensity).length ? (
                  <Typography gutterBottom variant="body1">
                    Livestock density (per hectare compared to the average
                    farm):
                    {Object.keys(livestockDensity).map((key) => (
                      <span key={key}>
                        <br />
                        {key}: {livestockDensity[key]}
                      </span>
                    ))}
                  </Typography>
                ) : null}
                {naturalWater && Object.keys(naturalWater).length ? (
                  <Typography gutterBottom variant="body1">
                    Natural water:
                    {Object.keys(naturalWater).map((key) => (
                      <span key={key}>
                        <br />
                        {strToUpperCase(key as WaterKeys)}:
                        {naturalWater[key as WaterKeys] ? "yes" : "no"}
                      </span>
                    ))}
                  </Typography>
                ) : null}
                {landUsage && Object.keys(landUsage).length ? (
                  <Typography gutterBottom variant="body1">
                    Land usage:
                    {Object.keys(landUsage).map((key) => (
                      <span key={key}>
                        <br />
                        {strToUpperCase(key.split("_").join(" "))}:
                        {landUsage[key as keyof LandUsage]}
                        {key !== "other" ? "%" : ""}
                      </span>
                    ))}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
