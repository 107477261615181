import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams } from "react-router";
import { useState } from "react";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import ShareRequestComponent from "../../components/shareRequestComponent/ShareRequestComponent";
import TitleComponent from "../../components/title/TitleComponent";
import { ShareRequestsResponseType } from "../../types/DataTypes";
import {
  TABLE_HEADERS_SHARE_REQUEST,
  TABLE_HEADERS_SHARE_REQUEST_ORGANIZATION,
} from "../../constants/tableConstants";
import { userRole } from "../../constants/userRoleConstants";

function ShareRequestMessagePage() {
  const [refresh, setRefresh] = useState(Math.random());
  const { id } = useParams();
  const { token, user } = useAuth();

  const { responseData, loading, error } = useFetch<ShareRequestsResponseType>({
    url: `${REACT_APP_API_URL}/share-requests/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
    refresh,
  });

  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };
  return (
    <Container>
      <div key={refresh}>
        {error && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h4" color="error">
              {error?.statusCode} {"  "}
              {error?.error}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box display="flex" justifyContent="center" mt={20}>
            <CircularProgress />
          </Box>
        )}
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={10} mt={10}>
            <TableContainer component={Paper}>
              <Box p={2} sx={{ textAlign: "center" }}>
                <TitleComponent variant="h6" title="Share requests" />
              </Box>
              <Divider />
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {user?.role &&
                      {
                        [userRole.organization_admin]: (
                          <>
                            {TABLE_HEADERS_SHARE_REQUEST_ORGANIZATION.map(
                              (label) => {
                                return (
                                  <TableCell align="center">
                                    {label.label}
                                  </TableCell>
                                );
                              }
                            )}
                          </>
                        ),
                        [userRole.farm_admin]: (
                          <>
                            {TABLE_HEADERS_SHARE_REQUEST.map((label) => {
                              return (
                                <TableCell align="center">
                                  {label.label}
                                </TableCell>
                              );
                            })}
                          </>
                        ),
                      }[user.role]}
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && !responseData && (
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {responseData && (
                    <ShareRequestComponent
                      refreshRecordingSiteList={refreshRecordingSiteList}
                      data={responseData}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default ShareRequestMessagePage;
