export type CountyItem = {
  value: string;
  label: string;
};
export const SELECT_ITEMS: CountyItem[] = [
  {
    value: "Derbyshire",
    label: "Derbyshire",
  },
  {
    value: "Leicestershire",
    label: "Leicestershire",
  },
  {
    value: "Lincolnshire",
    label: "Lincolnshire",
  },
  {
    value: "Northamptonshire",
    label: "Northamptonshire",
  },
  {
    value: "Nottinghamshire",
    label: "Nottinghamshire",
  },
  {
    value: "Rutland",
    label: "Rutland",
  },
  {
    value: "Bedfordshire",
    label: "Bedfordshire",
  },

  {
    value: "Cambridgeshire",
    label: "Cambridgeshire",
  },
  {
    value: "Essex",
    label: "Essex",
  },
  {
    value: "Hertfordshire",
    label: "Hertfordshire",
  },
  {
    value: "Norfolk",
    label: "Norfolk",
  },

  {
    value: "Suffolk",
    label: "Suffolk",
  },
  {
    value: "London",
    label: "London",
  },
  {
    value: "County Durham",
    label: "County Durham",
  },
  {
    value: "Northumberland",
    label: "Northumberland",
  },

  {
    value: "Tyne and Wear",
    label: "Tyne and Wear",
  },

  {
    value: "Cheshire",
    label: "Cheshire",
  },

  {
    value: "Cumbria",
    label: "Cumbria",
  },

  {
    value: "Greater Manchester",
    label: "Greater Manchester",
  },

  {
    value: "Lancashire",
    label: "Lancashire",
  },

  {
    value: "Merseyside",
    label: "Merseyside",
  },
  {
    value: "Berkshire",
    label: "Berkshire",
  },
  {
    value: "Buckinghamshire",
    label: "Buckinghamshire",
  },
  {
    value: "East Sussex",
    label: "East Sussex",
  },
  {
    value: "Hampshire",
    label: "Hampshire",
  },

  {
    value: "Isle of Wight",
    label: "Isle of Wight",
  },

  {
    value: "Kent",
    label: "Kent",
  },
  {
    value: "Oxfordshire",
    label: "Oxfordshire",
  },
  {
    value: "Surrey",
    label: "Surrey",
  },
  {
    value: "West Sussex",
    label: "West Sussex",
  },

  {
    value: "Bristol",
    label: "Bristol",
  },

  {
    value: "Cornwall",
    label: "Cornwall",
  },
  {
    value: "Devon",
    label: "Devon",
  },

  {
    value: "Dorset",
    label: "Dorset",
  },
  {
    value: "Gloucestershire",
    label: "Gloucestershire",
  },
  {
    value: "Somerset",
    label: "Somerset",
  },

  {
    value: "Wiltshire",
    label: "Wiltshire",
  },
  {
    value: "Herefordshire",
    label: "Herefordshire",
  },
  {
    value: "Shropshire",
    label: "Shropshire",
  },

  {
    value: "Staffordshire",
    label: "Staffordshire",
  },

  {
    value: "Warwickshire",
    label: "Warwickshire",
  },

  {
    value: "West Midlands",
    label: "West Midlands",
  },
  {
    value: "Worcestershire",
    label: "Worcestershire",
  },
  {
    value: "East Riding of Yorkshire",
    label: "East Riding of Yorkshire",
  },
  {
    value: "North Yorkshire",
    label: "North Yorkshire",
  },
  {
    value: "South Yorkshire",
    label: "South Yorkshire",
  },

  {
    value: "West Yorkshire",
    label: "West Yorkshire",
  },

  {
    value: "Antrim",
    label: "Antrim",
  },

  {
    value: "Armagh",
    label: "Armagh",
  },

  {
    value: "Derry-Londonderry",
    label: "Derry-Londonderry",
  },

  {
    value: "Down",
    label: "Down",
  },

  {
    value: "Fermanagh",
    label: "Fermanagh",
  },

  {
    value: "Tyrone",
    label: "Tyrone",
  },

  {
    value: "Carlow",
    label: "Carlow",
  },

  {
    value: "Cavan",
    label: "Cavan",
  },

  {
    value: "Clare",
    label: "Clare",
  },

  {
    value: "Cork",
    label: "Cork",
  },

  {
    value: "Dublin",
    label: "Dublin",
  },

  {
    value: "Kildare",
    label: "Kildare",
  },

  {
    value: "Kilkenny",
    label: "Kilkenny",
  },

  {
    value: "Laois",
    label: "Laois",
  },

  {
    value: "Leitrim",
    label: "Leitrim",
  },

  {
    value: "Limerick",
    label: "Limerick",
  },

  {
    value: "Longford",
    label: "Longford",
  },

  {
    value: "Louth",
    label: "Louth",
  },

  {
    value: "Meath",
    label: "Meath",
  },

  {
    value: "Monaghan",
    label: "Monaghan",
  },

  {
    value: "Offaly",
    label: "Offaly",
  },

  {
    value: "Roscommon",
    label: "Roscommon",
  },

  {
    value: "Tipperary",
    label: "Tipperary",
  },

  {
    value: "Waterford",
    label: "Waterford",
  },

  {
    value: "Westmeath",
    label: "Westmeath",
  },

  {
    value: "Wexford",
    label: "Wexford",
  },

  {
    value: "Wicklow",
    label: "Wicklow",
  },

  {
    value: "Donegal",
    label: "Donegal",
  },

  {
    value: "Galway",
    label: "Galway",
  },

  {
    value: "Kerry",
    label: "Kerry",
  },

  {
    value: "Mayo",
    label: "Mayo",
  },

  {
    value: "Sligo",
    label: "Sligo",
  },

  {
    value: "Other",
    label: "Other",
  },

  {
    value: "Aberdeen",
    label: "Aberdeen",
  },

  {
    value: "Aberdeenshire",
    label: "Aberdeenshire",
  },

  {
    value: "Angus",
    label: "Angus",
  },

  {
    value: "Clackmannanshire",
    label: "Clackmannanshire",
  },

  {
    value: "Dumfries and Galloway",
    label: "Dumfries and Galloway",
  },

  {
    value: "Dundee",
    label: "Dundee",
  },

  {
    value: "East Ayrshire",
    label: "East Ayrshire",
  },

  {
    value: "East Dunbartonshire",
    label: "East Dunbartonshire",
  },

  {
    value: "East Lothian",
    label: "East Lothian",
  },

  {
    value: "East Renfrewshire",
    label: "East Renfrewshire",
  },

  {
    value: "Edinburgh",
    label: "Edinburgh",
  },

  {
    value: "Falkirk",
    label: "Falkirk",
  },

  {
    value: "Fife",
    label: "Fife",
  },

  {
    value: "Glasgow",
    label: "Glasgow",
  },

  {
    value: "Inverclyde",
    label: "Inverclyde",
  },

  {
    value: "Midlothian",
    label: "Midlothian",
  },

  {
    value: "North Ayrshire",
    label: "North Ayrshire",
  },

  {
    value: "North Lanarkshire",
    label: "North Lanarkshire",
  },

  {
    value: "Perth and Kinross",
    label: "Perth and Kinross",
  },

  {
    value: "Renfrewshire",
    label: "Renfrewshire",
  },

  {
    value: "Scottish Borders",
    label: "Scottish Borders",
  },

  {
    value: "South Ayrshire",
    label: "South Ayrshire",
  },

  {
    value: "South Lanarkshire",
    label: "South Lanarkshire",
  },

  {
    value: "Stirlingshire",
    label: "Stirlingshire",
  },

  {
    value: "West Dunbartonshire",
    label: "West Dunbartonshire",
  },

  {
    value: "West Lothian",
    label: "West Lothian",
  },

  {
    value: "Argyll and Bute",
    label: "Argyll and Bute",
  },

  {
    value: "Highland",
    label: "Highland",
  },

  {
    value: "Moray",
    label: "Moray",
  },

  {
    value: "Orkney",
    label: "Orkney",
  },

  {
    value: "Shetland Isles",
    label: "Shetland Isles",
  },

  {
    value: "Western Isles",
    label: "Western Isles",
  },

  {
    value: "Blaenau Gwent",
    label: "Blaenau Gwent",
  },

  {
    value: "Bridgend",
    label: "Bridgend",
  },

  {
    value: "Caerphilly",
    label: "Caerphilly",
  },

  {
    value: "Cardiff",
    label: "Cardiff",
  },

  {
    value: "Carmarthenshire",
    label: "Carmarthenshire",
  },

  {
    value: "Ceredigion",
    label: "Ceredigion",
  },

  {
    value: "Conwy",
    label: "Conwy",
  },

  {
    value: "Denbighshire",
    label: "Denbighshire",
  },

  {
    value: "Flintshire",
    label: "Flintshire",
  },

  {
    value: "Gwynedd",
    label: "Gwynedd",
  },

  {
    value: "Isle of Anglesey",
    label: "Isle of Anglesey",
  },

  {
    value: "Merthyr Tydfil",
    label: "Merthyr Tydfil",
  },

  {
    value: "Monmouthshire",
    label: "Monmouthshire",
  },

  {
    value: "Neath Port Talbot",
    label: "Neath Port Talbot",
  },

  {
    value: "Newport",
    label: "Newport",
  },

  {
    value: "Pembrokeshire",
    label: "Pembrokeshire",
  },

  {
    value: "Powys",
    label: "Powys",
  },

  {
    value: "Rhondda Cynon Taf",
    label: "Rhondda Cynon Taf",
  },

  {
    value: "Swansea",
    label: "Swansea",
  },

  {
    value: "Torfaen",
    label: "Torfaen",
  },

  {
    value: "Vale of Glamorgan",
    label: "Vale of Glamorgan",
  },

  {
    value: "Wrexham",
    label: "Wrexham",
  },
];
