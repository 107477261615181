export const requestName: Record<string, string> = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED",
  PENDING: "PENDING",
  APPROVED_LIMITED: "LIMITED",
};

export const getRequestName = (request: string) => {
  return requestName[request] ?? request;
};

const requestStatusExplanations: Record<string, string> = {
  APPROVED:
    "Farm shares all of it's data, organizations can see their farm report and benchmark report.",
  APPROVED_LIMITED:
    "Farm shares only the data regarding species diversity, organizations can see benchmark report for that farm.",
  REJECTED:
    "Farm doesn't share their data but still appear anonymously in benchmark reports.",
  PENDING: "Waiting for request to be resolved",
  REVOKED: "Organization cancelled a request",
};

export const getRequestExplanation = (request: string) =>
  requestStatusExplanations[request] ?? request;
