import * as Yup from "yup";

export const validationSchemaResetPassword = Yup.object({
  newPass: Yup.string().required("The password is required"),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("newPass"), null], "Passwords must match!")
    .required("The confirmation password is required"),
  email: Yup.string().required("The email is required"),
  token: Yup.string().required("The token is required"),
});
