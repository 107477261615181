import React from "react";
import { TextField } from "@mui/material";
import { TextFieldPropsTypes } from "../../types/PropsTypes";

function InputFieldComponent({
  id,
  type,
  variant,
  fullWidth,
  color,
  placeholder,
  margin,
  size,
  value,
  name,
  label,
  onChange,
  onBlur,
  className,
  multiline,
  rows,
  disabled,
}: TextFieldPropsTypes) {
  return (
    <TextField
      disabled={disabled}
      rows={rows}
      multiline={multiline}
      id={id}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      color={color}
      placeholder={placeholder}
      margin={margin}
      name={name}
      size={size}
      value={value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      className={className}
    />
  );
}

export default InputFieldComponent;
