import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useFormik } from "formik";
import { Autocomplete, TextField } from "@mui/material";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import InputFieldComponent from "../../components/input/InputField";
import useMutate from "../../service/useMutate";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { SpeciesDataResponseType, ErrorTypes } from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";
import { notify } from "../../utils/notify";
import { validationSchemaSpecies } from "../../validation/validationSchemaSpecies";

import { SELECT_RATING_CONSTANTS } from "../../constants/selectRatingConstants";

function SpeciesEditPage() {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } = useFetch<SpeciesDataResponseType>({
    url: `${REACT_APP_API_URL}/species/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/species/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      english: responseData?.english || "",
      international: responseData?.international || "",
      habitat: responseData?.habitat || "",
      tropic: responseData?.tropic || "",
      song_url: responseData?.song_url || "",
      rating: responseData?.rating || "",
    },
    validationSchema: validationSchemaSpecies,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          notify("Species successfully updated!");
          navigate("/species");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  const {
    errors,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    handleSubmit,
    touched,
    isValid,
  } = formik;

  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <Box display="flex" justifyContent="center" mt={20}>
            <CircularProgress />
          </Box>
        )}
        {responseData && (
          <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <form onSubmit={handleSubmit}>
                <Box textAlign="center" p={3}>
                  <CardHeader title="Edit species information" />
                  <Divider />
                  <CardContent>
                    <Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          disabled
                          id="latin"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          size="small"
                          name="latin"
                          label="Latin name"
                          value={responseData?.latin}
                        />
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="english"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="english"
                          label="English name"
                          value={values?.english}
                          onChange={handleChange}
                        />
                        {errors.english && (
                          <FormHelperText error>
                            {errors.english}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="international"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="international"
                          label="International name"
                          value={values?.international}
                          onChange={handleChange}
                        />
                        {errors.international && (
                          <FormHelperText error>
                            {errors.international}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="habitat"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="habitat"
                          label="Habitat"
                          value={values?.habitat}
                          onChange={handleChange}
                        />
                        {errors.habitat && (
                          <FormHelperText error>
                            {errors.habitat}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="tropic"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="tropic"
                          label="Trophic"
                          value={values?.tropic}
                          onChange={handleChange}
                        />
                        {errors.tropic && (
                          <FormHelperText error>{errors.tropic}</FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="song_url"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="song_url"
                          label="Song URL"
                          value={values?.song_url}
                          onChange={handleChange}
                        />
                        {errors.song_url && (
                          <FormHelperText error>
                            {errors.song_url}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <Autocomplete
                          size="small"
                          id="rating"
                          value={SELECT_RATING_CONSTANTS.find(
                            (rating) => rating.value === values.rating
                          )}
                          options={SELECT_RATING_CONSTANTS}
                          getOptionLabel={(rating) => rating.label}
                          onChange={(e, rating) =>
                            setFieldValue("rating", rating?.value || "")
                          }
                          onBlur={handleBlur("rating")}
                          onOpen={handleBlur("rating")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="rating"
                              value={values.rating}
                              onBlur={handleBlur("rating")}
                              label="Rating"
                            />
                          )}
                        />
                        {touched.rating && errors.rating && (
                          <FormHelperText error>{errors.rating}</FormHelperText>
                        )}
                      </Box>
                    </Box>
                    <Button
                      disabled={!isValid}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  </CardContent>
                </Box>
              </form>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
export default SpeciesEditPage;
