import React from "react";
import { Link } from "react-router-dom";
import { TableCell, IconButton } from "@mui/material";
import { TableCellWithIconProps } from "../../types/PropsTypes";

function TableCellWithIcon({ path, children }: TableCellWithIconProps) {
  return (
    <TableCell>
      <span
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflow: "hidden",
          width: "90px",
          display: "block",
        }}
      >
        <Link to={path}>
          <IconButton>{children}</IconButton>
        </Link>
      </span>
    </TableCell>
  );
}
export default TableCellWithIcon;
