import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useMutate from "../../service/useMutate";

function VerificationPage() {
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const { fetchData, loading, error } = useMutate({
    url: `${REACT_APP_API_URL}/auth/verify`,
    method: "PUT",
  });

  useEffect(() => {
    fetchData({ email, token });
  }, []);

  const handleNavigate = () => {
    navigate(`/${email}`);
  };
  return (
    <Container>
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          md={5}
          alignItems="center"
          justifyContent="center"
          mt={30}
        >
          <Card elevation={3}>
            <Box textAlign="center" p={3}>
              <Typography variant="h6" pb={3}>
                You successfully verify your account. Now you can login!
              </Typography>
              <Button variant="contained" fullWidth onClick={handleNavigate}>
                Log in
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default VerificationPage;
