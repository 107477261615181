/* eslint-disable max-len */
export const DESCRIPTION_CONSTANTS = {
  firstCard:
    "<b>Bird presence:</b> Shows the number of days when each species was audibly present during the recording period, as a proportion of the total presence of all species. <br /> <b>Chart wedges:</b> Birds identified by the <a href='https://www.chirrup.ai/' target='_blank'>chirrup.ai</a> algorithm, represented by 5+ recordings/day.",
  secondCard:
    "<b>Threatened species:</b>  <b style='color:red'> Red list species </b> have suffered severe declines across the UK.<br /> <b>Birdsong audio:</b>  Click on the names to hear a clip.",
  thirdCard:
    "<b style='color:red'>Red List:</b>  <a style='color:#CE8514' href=https://www.bto.org target='_blank'/> Birds of conservation concern</a>  as defined by BTO. <br /> <b>Unique species:</b> Species not recorded at any other sites in the benchmark group, during the selected dates. <br /> Indicated with an asterisk* on species list.  <b>Birds of prey:</b>  Owls, kestrels and other raptors are indicators of a productive ecosystem and marked by a †.",

  // speciesListCard:
  //   "<b>Threatened species:</b>  Species in <b style='color:red'> red type </b> have suffered severe declines across the UK. <br /> <b>Unique species:</b> Species not recorded at any other sites in the benchmark group are indicated with an asterisk* <br /> <b>Birds of prey:</b>  Owls, kestrels and other raptors are indicators of a productive ecosystem and marked by a †.",
};
