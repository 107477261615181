import { useNavigate } from "react-router";
import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import InputFieldComponent from "../../components/input/InputField";
import useMutate from "../../service/useMutate";
// eslint-disable-next-line max-len
import { validationSchemaCreateOrganization } from "../../validation/validationSchemaCreateOrganization";
import { notify } from "../../utils/notify";
import { ErrorTypes } from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";
import { requestHeaderToken } from "../../utils/requestHeaderToken";

function OrganizationCreatePage() {
  const navigate = useNavigate();
  const { token } = useAuth();

  const { fetchData, error, loading } = useMutate({
    url: `${REACT_APP_API_URL}/organization`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      website_url: "",
      is_active: true,
    },
    validationSchema: validationSchemaCreateOrganization,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          notify("Organization successfully added!");
          navigate("/organization");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}

      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
          <Card elevation={3}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ textAlign: "center", p: 3 }}>
                <CardHeader title="Add new organization" />
                <Divider />
                <CardContent>
                  <Box sx={{ textAlign: "left" }}>
                    <Box mb={4} mt={4}>
                      <InputFieldComponent
                        id="name"
                        type="text"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size="small"
                        name="name"
                        label="Name"
                        value={formik.values?.name}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.name && (
                        <FormHelperText error>
                          {formik.errors.name}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box mb={4} mt={4}>
                      <InputFieldComponent
                        id="website_url"
                        type="text"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        fullWidth
                        name="website_url"
                        label="Website URL"
                        value={formik.values?.website_url}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.website_url && (
                        <FormHelperText error>
                          {formik.errors.website_url}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box mb={4} mt={4}>
                      <InputFieldComponent
                        id="description"
                        type="text"
                        multiline
                        rows={3}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        fullWidth
                        name="description"
                        label="Description"
                        value={formik.values?.description}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.description && (
                        <FormHelperText error>
                          {formik.errors.description}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                  <Button
                    disabled={!(formik.isValid && formik.dirty)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Save
                  </Button>
                </CardContent>
              </Box>
            </form>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
export default OrganizationCreatePage;
