import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import {
  FarmSelectionObject,
  OrganizationSelectionObject,
  PathParams,
} from "../../types/PropsTypes";
import InputFieldComponent from "../../components/input/InputField";
import useMutate from "../../service/useMutate";
import { validationSchemaEditUser } from "../../validation/validationSchemaEditUser";
import DisabledModalMessage from "../../components/modal/DisabledModalMessage";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { DataResponseType, ErrorTypes } from "../../types/DataTypes";
import BackComponent from "../../components/back/BackComponent";
import { notify } from "../../utils/notify";
import { userRole } from "../../constants/userRoleConstants";
import { getRoleName } from "../../utils/getRoleName";
import InputAutoComplete from "../../components/input/InputAutoComplete";

function UserEditPage() {
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams<PathParams>();
  const { token } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } = useFetch<DataResponseType>({
    url: `${REACT_APP_API_URL}/users/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/users/${id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const { responseData: responseDataFarms } = useFetch<FarmSelectionObject[]>({
    url: `${REACT_APP_API_URL}/farms/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData: responseDataOrganization } = useFetch<
    OrganizationSelectionObject[]
  >({
    url: `${REACT_APP_API_URL}/organization/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: responseData?.name || "",
      email: responseData?.email || "",
      role: responseData?.role || "",
      is_active: responseData?.is_active || false,
      farm: responseData?.farm || "",
      organization: responseData?.organization || "",
    },
    validationSchema: validationSchemaEditUser,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          notify("User successfully updated!");
          navigate("/users");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleCheckbox = (id: string) => {
    formik.handleChange(id);
    if (responseData) responseData.is_active = !responseData.is_active;
    handleClose();
  };

  const changeLabel = formik.values.is_active
    ? "Activated user"
    : "Deactivated user";

  const handleChangeFarm = (event: any, newValue: any) => {
    formik.setFieldValue("farm", newValue?.id ?? "");
  };
  const handleChangeOrganization = (event: any, newValue: any) => {
    formik.setFieldValue("organization", newValue?.id ?? "");
  };

  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
          </Typography>
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <Box display="flex" justifyContent="center" mt={20}>
            <CircularProgress />
          </Box>
        )}
        {responseData && (
          <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center", p: 3 }}>
                  <CardHeader title="Edit user information" />
                  <Divider />
                  <CardContent>
                    <Box sx={{ textAlign: "left" }}>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="name"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          size="small"
                          name="name"
                          label="Name"
                          value={formik.values?.name}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.name && (
                          <FormHelperText error>
                            {formik.errors.name}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="email"
                          type="email"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="email"
                          label="Email"
                          value={formik.values?.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.email && (
                          <FormHelperText error>
                            {formik.errors.email}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <TextField
                          select
                          fullWidth
                          name="role"
                          size="small"
                          id="role"
                          label="Role"
                          value={formik.values?.role}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value={formik.values?.role}>
                            {getRoleName(responseData.role)}
                          </MenuItem>
                        </TextField>
                        {formik.errors.role && (
                          <FormHelperText error>
                            {formik.errors.role}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        {responseData && responseDataFarms && (
                          <InputAutoComplete
                            onChange={handleChangeFarm}
                            value={
                              responseDataFarms?.filter(
                                (farm) => farm.id === responseData?.farm
                              )[0] ?? null
                            }
                            label="Farm"
                            disabled={
                              formik.values?.role === userRole.admin ||
                              formik.values?.role ===
                                userRole.organization_admin
                            }
                            options={responseDataFarms ?? []}
                            getOptionLabel={(farm) =>
                              (farm as FarmSelectionObject).name ?? ""
                            }
                          />
                        )}
                        {formik.errors.farm && (
                          <FormHelperText error>
                            {formik.errors.farm}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        {responseData && responseDataOrganization && (
                          <InputAutoComplete
                            onChange={handleChangeOrganization}
                            value={
                              responseDataOrganization?.filter(
                                (organization) =>
                                  organization.id === responseData?.organization
                              )[0] ?? null
                            }
                            label="Organization"
                            disabled={
                              formik.values?.role === userRole.admin ||
                              formik.values?.role === userRole.farm_admin
                            }
                            options={responseDataOrganization ?? []}
                            getOptionLabel={(organization) =>
                              (organization as OrganizationSelectionObject)
                                .name ?? ""
                            }
                          />
                        )}
                        {formik.errors.organization && (
                          <FormHelperText error>
                            {formik.errors.organization}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values?.is_active} />
                            }
                            id="check"
                            name="check"
                            label={changeLabel}
                            onClick={handleOpen}
                          />
                        </FormGroup>
                        {formik.errors.is_active && (
                          <FormHelperText error>
                            {formik.errors.is_active}
                          </FormHelperText>
                        )}
                        <DisabledModalMessage
                          name="user"
                          checked={formik.values?.is_active}
                          open={open}
                          handleActivate={handleToggleCheckbox}
                          handleDeactivate={handleToggleCheckbox}
                          handleClose={handleClose}
                        />
                      </Box>
                    </Box>
                    <Button
                      disabled={!formik.isValid}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  </CardContent>
                </Box>
              </form>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
export default UserEditPage;
