/* eslint-disable max-len */
import { ChangeEvent, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import { useFormik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ButtonComponent from "../button/ButtonComponent";
import useMutate from "../../service/useMutate";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import { styleModal } from "../../styles/customStyles";
import { notify } from "../../utils/notify";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import useFetch from "../../service/useFetch";
import { userRole } from "../../constants/userRoleConstants";
import { validationSchemaCreateShareRequest } from "../../validation/validationSchemaCreateShareRequest";
import { FarmSearchResponseType } from "../../types/DataTypes";
import useDebounce from "../../hooks/useDebounce";

function RequestDataShareModal({
  refreshRecordingSiteList,
}: {
  refreshRecordingSiteList?: () => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { token, user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { responseData: farmsData } = useFetch<FarmSearchResponseType[]>({
    url: `${REACT_APP_API_URL}/farms/search?search=${searchQuery}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(searchQuery),
  });

  const {
    error: errorSend,
    loading,
    fetchData,
  } = useMutate({
    url: `${REACT_APP_API_URL}/organization/share-request`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      farm_id: "",
    },
    validationSchema: validationSchemaCreateShareRequest,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          refreshRecordingSiteList?.();
          notify("Request successfully sent!");
          setOpen(false);
        })
        .catch((error) => {
          notify(`Request failed! ${error?.message}!`, error);
          setOpen(false);
        });
    },
  });
  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm((_prevSearch) => e.target.value);
  };
  useDebounce(() => {
    setSearchQuery(searchTerm);
  }, searchTerm);
  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      {user?.role &&
        {
          [userRole.organization_admin]: (
            <Box>
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={handleOpen}
              >
                Request farm data share
              </Button>
            </Box>
          ),
        }[user.role]}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </IconButton>
          <Typography
            textAlign="center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Request farm data share
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box mb={4} mt={4}>
              <Autocomplete
                id="farm_id"
                options={farmsData || []}
                filterOptions={(option) => option}
                getOptionLabel={(farm: FarmSearchResponseType) =>
                  `${farm.name} ${farm.post_code}`
                }
                onChange={(e, value) =>
                  formik.setFieldValue("farm_id", value?.id || "")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Farm"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                )}
              />
              <FormHelperText>
                Search by farm name, post code or farm admin e-mail
              </FormHelperText>
              {formik.errors.farm_id && (
                <FormHelperText error>{formik.errors.farm_id}</FormHelperText>
              )}
            </Box>
            <ButtonComponent
              type="submit"
              title="Submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            />
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default RequestDataShareModal;
