import { Navigate, Outlet } from "react-router";
import NavBarComponent from "../components/navbar/NavbarComponent";
import { userRole } from "../constants/userRoleConstants";
import { useAuth } from "../context/AuthContext";

const navigateRoles = {
  [userRole.admin]: "/users",
  [userRole.farm_admin]: "/farms",
  [userRole.organization_admin]: "/organization",
};
function PublicRoute() {
  const { user, isAuthFinish } = useAuth();
  if (!user && isAuthFinish) {
    return <Outlet />;
  }
  return (
    <NavBarComponent>
      {user && <Navigate to={navigateRoles[user.role]} />}
    </NavBarComponent>
  );
}
export default PublicRoute;
