import { TableCell, Chip } from "@mui/material";
import dayjs from "dayjs";
import { ColorStatus } from "../../types/DataTypes";
import { colorStatus } from "../../constants/colorStatusConstants";
import { ShareRequestComponentPropsType } from "../../types/PropsTypes";
import {
  getRequestName,
  getRequestExplanation,
} from "../../utils/getRequestName";
import PopoverOnHover from "../popover/PopoverOnHover";
import ShareRequestActions from "./ShareRequestActions";

function ShareRequestComponent({
  data,
  path,
  refreshRecordingSiteList,
}: ShareRequestComponentPropsType) {
  return (
    <>
      <TableCell align="center">{data.farm_name}</TableCell>
      <TableCell align="center">
        {path === "organization"
          ? data.request_user.name
          : data.organization.name}
      </TableCell>
      <TableCell align="center">
        {dayjs(data.created_at).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell align="center">
        <PopoverOnHover
          renderElement={({ handlePopoverOpen, handlePopoverClose, open }) => (
            <Chip
              label={getRequestName(data.status)}
              color={colorStatus[data.status as keyof ColorStatus]}
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          )}
        >
          {getRequestExplanation(data.status)}
        </PopoverOnHover>
      </TableCell>
      <TableCell align="center">
        <ShareRequestActions
          id={data.id}
          refreshRecordingSiteList={refreshRecordingSiteList}
          status={data.status}
        />
      </TableCell>
    </>
  );
}
export default ShareRequestComponent;
