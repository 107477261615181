import dayjs from "dayjs";
import Paper from "@mui/material/Paper/Paper";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import Table from "@mui/material/Table/Table";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {
  Box,
  Chip,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableFooter,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import {
  ColorStatus,
  Order,
  PaginationWithSort,
  ShareRequestsResponseType,
} from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { TABLE_HEADERS_SHARE_REQUEST } from "../../constants/tableConstants";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { colorStatus } from "../../constants/colorStatusConstants";
import {
  getRequestExplanation,
  getRequestName,
} from "../../utils/getRequestName";
import useDebounce from "../../hooks/useDebounce";
import { FarmSelectionObject } from "../../types/PropsTypes";
import InputAutoComplete from "../../components/input/InputAutoComplete";
import { STATUS_CONSTANTS } from "../../constants/statusConstants";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";
import { ShareRequestStatus, OrderEnum } from "../../constants/enums";
import RequestDataShareModal from "../../components/modal/RequestDataShareModal";
import PopoverOnHover from "../../components/popover/PopoverOnHover";
import ShareRequestActions from "../../components/shareRequestComponent/ShareRequestActions";

function ListShareRequestsTable() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sort, setSort] = useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [cphNumber, setCphNumber] = useState<string>("");
  const [farmName, setFarmName] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [status, setStatus] = useState<ShareRequestStatus>(
    ShareRequestStatus.ALL
  );
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [debouncedValuePostCode, setDebouncedValuePostCode] =
    useState<string>("");
  const { token } = useAuth();
  const [refresh, setRefresh] = useState(Math.random());

  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };
  const { responseData, loading, error } = useFetch<
    PaginationWithSort<ShareRequestsResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/share-requests?page=${page}&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&CPH_number=${cphNumber}&post_code=${postCode}&farm_name=${farmName}&status=${status}`,
    method: "GET",
    headers: requestHeaderToken(token),
    refresh,
  });

  const { responseData: farmsData } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  useDebounce(() => {
    setCphNumber(debouncedValue);
  }, debouncedValue);

  useDebounce(() => {
    setPostCode(debouncedValuePostCode);
  }, debouncedValuePostCode);

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDebouncedValue(event.target.value);
    setPage(0);
  };
  const handleSearchPostCode = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDebouncedValuePostCode(event.target.value);
    setPage(0);
  };

  const handleChange = (event: any, newValue: any) => {
    setFarmName(newValue?.name ?? "");
    setPage(0);
  };
  const handleChangeStatus = (event: any, newValue: any) => {
    setStatus(newValue?.value ?? "");
    setPage(0);
  };
  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };
  return (
    <Container>
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <BackComponent />
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message?.status}
          </Typography>
        </Box>
      )}
      <Grid
        container
        mb={4}
        flexDirection="row"
        justifyContent="space-between"
        gap={1}
      >
        <Grid
          container
          item
          flexDirection="row"
          gap={0.5}
          xs={12}
          sm={12}
          md={8}
        >
          <Grid item xs={12} sm={12} md={6} order={1}>
            <OutlinedInput
              id="searchCPH"
              name="searchCPH"
              placeholder="Filter share requests by CPH number..."
              type="text"
              size="small"
              onChange={handleSearch}
              value={debouncedValue}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={3}>
            <OutlinedInput
              id="searchPostCode"
              name="searchPostCode"
              placeholder="Filter share requests by post code..."
              type="text"
              size="small"
              onChange={handleSearchPostCode}
              value={debouncedValuePostCode}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} order={2}>
            <InputAutoComplete
              id="farmFilter"
              value={null}
              label="Farm"
              onChange={handleChange}
              options={farmsData ?? []}
              getOptionLabel={(farm) =>
                (farm as FarmSelectionObject).name ?? ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} order={4}>
            <InputAutoComplete
              id="statusFilter"
              value={null}
              label="Status"
              onChange={handleChangeStatus}
              options={STATUS_CONSTANTS}
              getOptionLabel={(option) => (option as any).label ?? ""}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <RequestDataShareModal
            refreshRecordingSiteList={refreshRecordingSiteList}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  {TABLE_HEADERS_SHARE_REQUEST.map(({ sortName, label }) => {
                    return (
                      <TableCell
                        className="tableCell"
                        key={label}
                        onClick={() => handleSort(sortName)}
                        sx={{ cursor: "pointer" }}
                        align="center"
                      >
                        {label}
                        {sortName && (
                          <TableSortLabel
                            active={sort === sortName}
                            direction={sort === sortName ? order : "asc"}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && !responseData && (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {responseData?.results.map(
                  (data: ShareRequestsResponseType) => {
                    return (
                      <TableRow key={data.id}>
                        <TableCellWithDiv content={data.farm_name} />
                        <TableCellWithDiv content={data.organization.name} />
                        <TableCellWithDiv
                          content={dayjs(data.created_at).format("DD/MM/YYYY")}
                        />
                        <TableCellWithDiv
                          content={
                            <PopoverOnHover
                              renderElement={({
                                handlePopoverOpen,
                                handlePopoverClose,
                                open,
                              }) => (
                                <Chip
                                  label={getRequestName(data.status)}
                                  color={
                                    colorStatus[
                                      data.status as keyof ColorStatus
                                    ]
                                  }
                                  aria-owns={
                                    open ? "mouse-over-popover" : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={handlePopoverOpen}
                                  onMouseLeave={handlePopoverClose}
                                />
                              )}
                            >
                              {getRequestExplanation(data.status)}
                            </PopoverOnHover>
                          }
                        />
                        <TableCellWithDiv
                          content={
                            <ShareRequestActions
                              id={data.id}
                              status={data.status}
                              refreshRecordingSiteList={
                                refreshRecordingSiteList
                              }
                            />
                          }
                        />
                      </TableRow>
                    );
                  }
                )}
              </TableBody>

              <PaginationComponent
                count={responseData?.metadata.count}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Table>
          </Box>
        </Box>
      </TableContainer>
    </Container>
  );
}
export default ListShareRequestsTable;
