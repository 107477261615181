import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavListProps } from "../../types/PropsTypes";
import BadgeComponent from "../badge/BadgeComponent";

export default function ItemForSideMenu({
  item,
  userRole,
  badge,
}: NavListProps) {
  const { textByRole, icon, path, allowed, children } = item;
  const text = textByRole[userRole] || textByRole.default;
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenList = () => {
    setOpen((prev) => !prev);
  };
  if (!(allowed.includes("*") || allowed.includes(userRole))) {
    return null;
  }
  if (!children) {
    return (
      <NavLink
        to={path}
        style={{
          textDecoration: "none",
          color: "inherit",
          pointerEvents: item.disabled ? "none" : "auto",
        }}
      >
        {({ isActive }) => (
          <ListItem key={text}>
            <ListItemButton disabled={item.disabled} selected={isActive}>
              <ListItemIcon>
                {badge ? (
                  <BadgeComponent badgeContent={badge}>{icon}</BadgeComponent>
                ) : (
                  icon
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        )}
      </NavLink>
    );
  }
  return (
    <ListItem key={text}>
      <Box sx={{ overflow: "auto", width: "100%" }}>
        <ListItemButton onClick={handleOpenList}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List dense disablePadding>
            {children.map((childItem, index) => {
              return (
                <ItemForSideMenu
                  key={index}
                  item={childItem}
                  userRole={userRole}
                />
              );
            })}
          </List>
        </Collapse>
      </Box>
    </ListItem>
  );
}
