export const COLORS = [
  "#F4EF00",
  "#F9D793",
  "#f6c34e",
  "#F5B61F",
  "#CE8514",
  "#8D5C25",
  "#CCC",
];
export const getColor = (index: number) => {
  return COLORS[index < 7 ? index : 6];
};
