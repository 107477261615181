import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useState } from "react";

export default function YearsDropdown({
  onChange,
  selectedYear,
}: {
  onChange: (value: number) => void;
  selectedYear: number;
}) {
  const currentYear = new Date().getFullYear();
  const years: number[] = Array(new Date().getFullYear() - 2021)
    .fill(0)
    .map((v, i) => i + 2022);

  return (
    <Autocomplete
      size="small"
      style={{ width: "200px" }}
      disablePortal
      value={selectedYear}
      onChange={(_e: any, value: number | null) => {
        onChange(value || currentYear);
      }}
      id="combo-box-demo"
      options={years}
      getOptionLabel={(option: number) => option.toString() || ""}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Year" />}
    />
  );
}
