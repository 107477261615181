import React, { ReactElement, useState } from "react";
import { Box, Button, Modal, SxProps, Theme, Typography } from "@mui/material";
import { theme } from "../../styles/useTheme";

const style: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: `2px solid ${theme.palette.primary.dark}`,
  boxShadow: 24,
  p: 4,
  display: "flex",
  gap: 3,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "3%",
};

interface ConfirmationModalProps {
  renderElement?: ({ openModal }: { openModal: () => void }) => ReactElement;
  onConfirm: () => void;
  onCancel?: () => void;
  text?: string;
  btnConfirmText?: string;
  btnCancelText?: string;
  initiallyOpen?: boolean;
}
export default function ConfirmationModal({
  renderElement,
  onConfirm,
  onCancel,
  text,
  btnConfirmText,
  btnCancelText,
  initiallyOpen,
}: ConfirmationModalProps) {
  const [open, setOpen] = useState<boolean>(initiallyOpen || false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };
  return (
    <>
      {renderElement?.({ openModal: handleOpen })}
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            {text || "Please confirm!"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            <Button onClick={handleConfirm} variant="contained" color="primary">
              {btnConfirmText || "Confirm"}
            </Button>
            <Button onClick={handleCancel} variant="contained" color="primary">
              {btnCancelText || "Cancel"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
