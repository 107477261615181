import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import {
  FetchComponentPropsType,
  TypeOfFetchComponentType,
} from "../../types/PropsTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";

function FetchComponent({ id, path }: FetchComponentPropsType) {
  const { token } = useAuth();
  const { responseData } = useFetch<TypeOfFetchComponentType>({
    url: `${REACT_APP_API_URL}/${path}/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return <span>{responseData && responseData.name}</span>;
}

export default FetchComponent;
