export const SELECT_ROLE = [
  {
    value: "ADMIN",
    label: "Admin",
  },
  {
    value: "FARM_ADMIN",
    label: "Farm administrator",
  },
  {
    value: "ORGANIZATION_ADMIN",
    label: "Organization admin",
  },
];
