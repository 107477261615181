import React from "react";
import { FormikProps, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  beverageSuppliersList,
  dairySuppliersList,
  meatSuppliersList,
  retailSuppliersList,
  sustainabilitySystemsList,
} from "./helpers";
import CheckboxList from "./CheckboxList";
import Livestock from "./Livestock";
import NaturalWater from "./NaturalWater";
import LandUsage from "./LandUsage";
import { FullFarmData } from "../../types/DataTypes";
import { validationSchemaAdditionalData } from "../../validation/validationSchemaAdditionalData";

export default function FarmAdditionalInfoForm({
  initialValues,
  onSubmit,
}: {
  initialValues: FullFarmData;
  onSubmit: (values: FullFarmData) => void;
}) {
  const formik: FormikProps<FullFarmData> = useFormik<FullFarmData>({
    initialValues,
    onSubmit,
    validationSchema: validationSchemaAdditionalData,
  });
  const {
    values: { additional_data: valuesAdditional },
    touched: { additional_data: touchedAdditional },
    errors: { additional_data: errorsAdditional },
  } = formik;
  const {
    farm_size: farmSizeTouched,
    sustainability_systems: sustainabilitySystemsTouched,
    retail_suppliers: retailSuppliersTouched,
    dairy_suppliers: dairySuppliersTouched,
    meat_suppliers: meatSuppliersTouched,
    beverage_suppliers: beverageSuppliersTouched,
  } = touchedAdditional || {};
  const {
    farm_size: farmSizeErrors,
    sustainability_systems: sustainabilitySystemsErrors,
    retail_suppliers: retailSuppliersErrors,
    dairy_suppliers: dairySuppliersErrors,
    meat_suppliers: meatSuppliersErrors,
    beverage_suppliers: beverageSuppliersErrors,
  } = errorsAdditional || {};
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} width="100%" alignContent="center">
          <Grid item md={12}>
            <Box>
              <TextField
                name="additional_data.farm_size"
                label="Farm size"
                value={valuesAdditional.farm_size}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ha</InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              {farmSizeTouched && farmSizeErrors && (
                <FormHelperText error>{farmSizeErrors}</FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <CheckboxList
              label="Are any of the following farm management / reporting systems used for sustainability?"
              options={sustainabilitySystemsList}
              name="additional_data.sustainability_systems"
            />
            {sustainabilitySystemsTouched && sustainabilitySystemsErrors && (
              <FormHelperText error>
                {sustainabilitySystemsErrors}
              </FormHelperText>
            )}
          </Grid>
          <Grid item md={6}>
            <CheckboxList
              label="Does it have a supply relationship with any retailer?"
              options={retailSuppliersList}
              name="additional_data.retail_suppliers"
            />
            {retailSuppliersTouched && retailSuppliersErrors && (
              <FormHelperText error>{retailSuppliersErrors}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6}>
            <CheckboxList
              label="Does it have a supply relationship with a dairy processor / manufacturer?"
              options={dairySuppliersList}
              name="additional_data.dairy_suppliers"
            />
            {dairySuppliersTouched && dairySuppliersErrors && (
              <FormHelperText error>{dairySuppliersErrors}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6}>
            <CheckboxList
              label="Does it have a supply relationship with a meat processor / manufacturer?"
              options={meatSuppliersList}
              name="additional_data.meat_suppliers"
            />
            {meatSuppliersTouched && meatSuppliersErrors && (
              <FormHelperText error>{meatSuppliersErrors}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6}>
            <CheckboxList
              label="Does it have a supply relationship with any of the following beverage companies?"
              options={beverageSuppliersList}
              name="additional_data.beverage_suppliers"
            />
            {beverageSuppliersTouched && beverageSuppliersErrors && (
              <FormHelperText error>{beverageSuppliersErrors}</FormHelperText>
            )}
          </Grid>
          <Grid item md={12} width="100%">
            <Livestock name="additional_data.livestock_stocking_density" />
          </Grid>
          <Grid item md={12} width="100%">
            <NaturalWater name="additional_data.natural_water" />
          </Grid>
          <Grid item md={12} width="100%">
            <LandUsage name="additional_data.land_usage" />
          </Grid>
          <Grid item md={12}>
            <Button size="large" fullWidth variant="contained" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}
