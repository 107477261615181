export const styleUpload = {
  position: "relative" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "90vh",
  bgcolor: "#fff5e1",
  border: "1px solid #fbfbfb",
  boxShadow: 24,
  p: 4,
  display: "block",
};

export const styleUploadRejected = {
  ...styleUpload,
  overflowY: "scroll",
};
export const styleModal = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "1px solid #fbfbfb",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export const styleModalShareRequest = {
  ...styleModal,
  width: "50%",
};
