import { Card, Box } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { UploadError } from "../../utils/uploadFiles";

const CODE_TO_ENG: Record<string, string> = {
  NOT_VALID_NAME: "File name format is not valid",
  ALREADY_EXISTS: "File is already uploaded for this site",
  AWS_UPLOAD_FAIL: "Error while uploading to aws",
  UPLOAD_CONFIRM_FAIL: "Error while confirming upload",
};

function FileUploadErrorCard({ fileName, code, sevirity }: UploadError) {
  return (
    <Card
      sx={{
        background: sevirity === "ERROR" ? "#e04a4a20" : "#fcf7ed",
        padding: "10px 20px",
        marginBottom: "5px",
        position: "relative",
      }}
      key={fileName}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Box>{fileName}</Box>
          <Typography variant="caption" color="gray">
            {CODE_TO_ENG[code] ?? ""}
          </Typography>
        </Stack>
        <Box>
          {sevirity === "ERROR" ? (
            <ClearIcon fontSize="medium" color="error" />
          ) : (
            <InfoIcon fontSize="medium" color="info" />
          )}
        </Box>
      </Box>
    </Card>
  );
}
export default FileUploadErrorCard;
