import * as Yup from "yup";
import { validationSchemaCreateFarm } from "./validationSchemaCreateFarm";

export const validationSchemaAdditionalData = Yup.object().shape({
  ...validationSchemaCreateFarm.fields,
  additional_data: Yup.object({
    farm_size: Yup.number()
      .min(1, "Farm size cannot be 0")
      .required("Farm size is required"),
    sustainability_systems: Yup.array()
      .min(1, "Please select at least one option")
      .of(Yup.string())
      .required("Please select at least one option"),
    retail_suppliers: Yup.array()
      .min(1, "Please select at least one option")
      .of(Yup.string())
      .required("Please select at least one option"),
    dairy_suppliers: Yup.array()
      .min(1, "Please select at least one option")
      .of(Yup.string())
      .required("Please select at least one option"),
    meat_suppliers: Yup.array()
      .min(1, "Please select at least one option")
      .of(Yup.string())
      .required("Please select at least one option"),
    beverage_suppliers: Yup.array()
      .min(1, "Please select at least one option")
      .of(Yup.string())
      .required("Please select at least one option"),
    livestock_stocking_density: Yup.object()
      .test(
        "notEmpty",
        "Please select or add at least one option for livestock",
        (value) => {
          return Object.keys(value).length > 0;
        }
      )
      .required(),
    natural_water: Yup.object()
      .test(
        "notEmpty",
        "Please mark if you have natural water on your farm.",
        (value) => {
          return Object.keys(value).length > 0;
        }
      )
      .required(),
    land_usage: Yup.object()
      .test(
        "validPercentage",
        "Percentages must be between 0 and 100. Please fill out at least one field.",
        (value) => {
          const properties = Object.values(value);
          const isAnyFilled = properties.some(
            (prop) =>
              prop !== undefined && prop !== null && prop !== "" && prop !== 0
          );

          if (!isAnyFilled) {
            return false; // At least one property must be filled
          }

          return properties.every(
            (prop) =>
              prop === undefined ||
              prop === null ||
              (typeof prop === "number" && prop >= 0 && prop <= 100)
          );
        }
      )
      .required(),
  }),
});
