import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import ReportCard from "../../../components/cards/ReportCard";
import { SpeciesReportType } from "../../../types/DataTypes";
import { COLORS, getColor } from "./ChartColors/ChartColors";

export default function SpeciesPieChart({
  species,
}: {
  species: SpeciesReportType[];
}) {
  const PIE_CHART_OPTIONS: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: "black",
        display: (context: Context) => {
          return context.dataIndex < COLORS.length - 1; // display labels only for colored pies
        },
      },
    },
  };
  const PIE_CHART_PLUGINS: any = [ChartDataLabels];
  const buildPieChartData = (
    species: SpeciesReportType[]
  ): ChartData<"doughnut", number[], unknown> => {
    const labels: string[] = species.map(
      ({ english: engName }: SpeciesReportType) => engName
    );
    const numbers: number[] = species.map(
      ({ n: numberOfBirds }: SpeciesReportType) => numberOfBirds
    );
    const colors: string[] = species.map((_specie, index) => getColor(index));
    return {
      labels,
      datasets: [
        {
          data: numbers,
          backgroundColor: colors,
          hoverOffset: 5,
        },
      ],
    };
  };
  const pieChartData = useMemo(() => buildPieChartData(species), [species]);
  return (
    <ReportCard
      title="Species days"
      sx={{ height: { md: "60%" }, minHeight: "auto" }}
    >
      <Box position="relative" width="95%" m="auto">
        <Doughnut
          data={pieChartData}
          options={PIE_CHART_OPTIONS}
          plugins={PIE_CHART_PLUGINS}
        />
      </Box>
    </ReportCard>
  );
}
