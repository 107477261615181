import StarIcon from "@mui/icons-material/Star";

function StarsRating({ rating }: { rating: number }) {
  if (!rating) {
    return <div>N/A</div>;
  }
  if (rating === 4) {
    return <div>Untrained</div>;
  }

  const getColor = (index: number) => (index < rating ? "primary" : "action");

  return (
    <div className="flex gap-2">
      <StarIcon key={0} color={getColor(0)} />
      <StarIcon key={1} color={getColor(1)} />
      <StarIcon key={2} color={getColor(2)} />
    </div>
  );
}

export default StarsRating;
