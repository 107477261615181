import AgricultureIcon from "@mui/icons-material/Agriculture";
import Box from "@mui/material/Box";

function BenchmarkGroupsIcon() {
  return (
    <Box position="relative">
      <AgricultureIcon sx={{ fontSize: "13px" }} />
      <Box sx={{ position: "absolute", bottom: 3, left: 12 }}>
        <AgricultureIcon fontSize="small" />
      </Box>
    </Box>
  );
}

export default BenchmarkGroupsIcon;
